import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Login from "../auth/Login/Login";
import Register from "../auth/Register/Register";
import Booking from "../dashboard/Booking/Booking";
import Dashboard from "../dashboard/Dashboard/Dashboard";
import DashboardMain from "../dashboard/DashboardLayout/DashboardMain/DashboardMain";
import AddProduct from "../dashboard/Products/AddProduct/AddProduct";
import MyProducts from "../dashboard/Products/MyProducts/MyProducts";
import ReportedProducts from '../dashboard/Products/ReportedProducts/ReportedProducts';
import Buyers from "../dashboard/Users/Buyers/Buyers";
import Sellers from "../dashboard/Users/Sellers/Sellers";
import Users from "../dashboard/Users/Users";
import Main from "../layout/Main/Main";
import NotFound404 from "../others/NotFound404/NotFound404";
import About from "../pages/About/About";
import Blog from "../pages/Blog/Blog";
import Home from "../pages/Home/Home";
import Products from "../pages/Products/Products";
import BrandProducts from "../pages/Products/ProductsBrand/BrandProducts/BrandProducts";
import ProductsBrand from "../pages/Products/ProductsBrand/ProductsBrand";
import CategoryProducts from "../pages/Products/ProductsCategory/CategoryProducts/CategoryProducts";
import ProductsCategory from "../pages/Products/ProductsCategory/ProductsCategory";
import AdminRoutes from "./AdminRoutes/AdminRoutes";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import SellerRoutes from './SellerRoutes/SellerRoutes';
import HomePage from '../pages/Home/HomePage';
import SingleCar from '../pages/SingleCar/SingleCar';
import { restApiUri } from '../constants';
import Contact from '../pages/Contact/Contact';
import CarInventory from '../pages/CarInventory/CarInventory';
import SellWithUs from '../pages/SellWithUs/SellWithUs';
import FaqPage from '../pages/Faq/FaqPage';
import BrandsModel from '../dashboard/BrandsModel/BrandsModel';
import ScrollToTop from '../scrollToTop';
import FeedbackForm from '../dashboard/Feedback/FeedbackForm';
import Feedbacks from '../dashboard/Feedback/Feedbacks';
import BannerGallery from '../dashboard/Upload/BannerGallery';
import SavedVehicle from '../dashboard/Products/SavedVehicle/SavedVehicle';

const router = createBrowserRouter([

  {
    path: '/',
    element: <Main><ScrollToTop></ScrollToTop></Main>,
    children: [
      {
        path: '/home-old',
        element: <Home></Home>
      },
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/cars',
        element: <Products></Products>
      },
      {
        path: '/faq',
        element: <FaqPage></FaqPage>
      },
      {
        path: '/category',
        element: <ProductsCategory></ProductsCategory>
      },
      {
        path: '/category/:slug',
        loader: ({ params }) => fetch(`${restApiUri}/category/${params.slug}`),
        element: <PrivateRoutes><CategoryProducts></CategoryProducts></PrivateRoutes>
      },
      {
        path: '/car/:slug',
        loader: ({ params }) => fetch(`${restApiUri}/product/${params.slug}`),
        element: <SingleCar />
      },
      {
        path: '/brand',
        element: <ProductsBrand></ProductsBrand>
      },
      {
        path: '/brand/:slug',
        loader: ({ params }) => fetch(`${restApiUri}/brand/${params.slug}`),
        element: <PrivateRoutes><BrandProducts></BrandProducts></PrivateRoutes>
      },
      {
        path: '/blog',
        element: <Blog></Blog>
      },
      {
        path: '/about',
        element: <About></About>
      },
      {
        path: '/contact-us',
        element: <Contact></Contact>
      },
      {
        path: '/car-inventory',
        element: <CarInventory></CarInventory>
      },
      {
        path: '/sell-with-us',
        element: <SellWithUs></SellWithUs>
      },
      {
        path: '/share-feedback',
        element: <FeedbackForm />
      },
      {
        path: '/login',
        element: <Login></Login>
      },
      {
        path: '/register',
        element: <Register></Register>
      }
      ,
      {
        path: '/*',
        element: <NotFound404></NotFound404>
      }
    ]
  },
  {
    path: '/dashboard',
    element: <DashboardMain></DashboardMain>,
    children: [
      {
        path: '/dashboard',
        element: <PrivateRoutes><Dashboard></Dashboard></PrivateRoutes>
      },
      {
        path: '/dashboard/bookings',
        element: <PrivateRoutes><Booking></Booking></PrivateRoutes>
      },
      {
        path: '/dashboard/feedbacks',
        element: <AdminRoutes><Feedbacks /></AdminRoutes>
      },
      {
        path: '/dashboard/banners-gallery',
        element: <AdminRoutes><BannerGallery /></AdminRoutes>
      },
      {
        path: '/dashboard/products',
        element: <PrivateRoutes><MyProducts></MyProducts></PrivateRoutes>
      },
      {
        path: '/dashboard/saved-vehicles',
        element: <PrivateRoutes><SavedVehicle></SavedVehicle></PrivateRoutes>
      },
      {
        path: '/dashboard/add-product',
        element: <PrivateRoutes><AddProduct></AddProduct></PrivateRoutes>
      },
      {
        path: '/dashboard/sellers',
        element: <AdminRoutes><Sellers></Sellers></AdminRoutes>
      },
      {
        path: '/dashboard/brands-model',
        element: <AdminRoutes><BrandsModel></BrandsModel></AdminRoutes>
      },
      {
        path: '/dashboard/buyers',
        element: <AdminRoutes><Buyers></Buyers></AdminRoutes>
      }
      ,
      {
        path: '/dashboard/users',
        element: <AdminRoutes><Users></Users></AdminRoutes>
      }
      ,
      {
        path: '/dashboard/reported-products',
        element: <AdminRoutes><ReportedProducts></ReportedProducts></AdminRoutes>
      }
    ]
  }
])

export default router;