import React, { useEffect } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const Main = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToAvailableCars = window.location.href.includes('home')
    if (scrollToAvailableCars) {
      window.scrollTo(0, 0)
    }
  }, [pathname])
  return (
    <>
      <Header></Header>
      <Outlet></Outlet>
      <Footer></Footer>
      <Toaster />
    </>
  );
};

export default Main;