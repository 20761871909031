import React, { useEffect, useState } from 'react';
import GallaryImages from "../../util/gallaryImages.json"
import { Link } from 'react-router-dom';
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import { Spinner } from "react-bootstrap";

const About = () => {

  const images = GallaryImages?.img;

  const [uploadedImages, setUploadedImages] = useState([]);
  const [isFetching, setFetching] = useState(false)

  const fetchUploadedImages = async () => {
    setFetching(true)
    try {
      const storage = getStorage();
      const bannerRef = ref(storage, 'gallery');
      const images = await listAll(bannerRef);
      const uploadedImagesData = [];

      // Fetch metadata for each image
      const metadataPromises = images.items.map(async (itemRef) => {
        const url = await getDownloadURL(ref(itemRef));
        const metadata = await getMetadata(ref(itemRef));
        const imageData = {
          url: url,
          metadata: metadata,
        };
        uploadedImagesData.push(imageData);
      });

      // Wait for all metadata to be fetched
      await Promise.all(metadataPromises);
      // Set state with the uploaded images data
      setUploadedImages(uploadedImagesData);
      setFetching(false)
    } catch (error) {
      console.error('Error fetching uploaded images:', error);
      setFetching(false)
    }
  };
  useEffect(() => {
    fetchUploadedImages();
  }, []); // Empty dependency array to run only once on mount

  return (
    <>
      <div className="inner-page-banner">
        <div className="banner-wrapper container">
          <div className="container-fluid">
            <ul id="breadcrumb-list" className="breadcrumb-list">
              <li className="breadcrumb-item">
               <Link to="/">Home </Link>
              </li>
              <li className="active">About Us</li>
            </ul>
            <div className="banner-main-content-wrap">
              <div className="row">
                <div className="col-xl-6 col-lg-7  d-flex align-items-center">
                  <div className="banner-content">
                    <h1>About Us</h1>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 d-lg-flex d-none align-items-center justify-content-end">
                  <div className="banner-img">
                    <img
                      src="../wp-content/plugins/arjan-core/inc/theme-options/images/breadcrumb/breadcrumb-right-img.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-wrapper ">
        <div className="page-content-wrapper container">
          <div
            className="elementor elementor-28"
          >
            <div className="elementor-inner">
              <div className="elementor-section-wrap">
                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-8ea28a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="8ea28a8"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-67f89fc"
                      >
                        <div className="elementor-column-wrap elementor-element-populated">
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-6c68598 elementor-widget elementor-widget-arjan_wellcome_banner"
                              data-id="6c68598"
                              data-element_type="widget"
                              data-widget_type="arjan_wellcome_banner.default"
                            >
                              <div className="elementor-widget-container" style={{ marginTop: '50px' }}>
                                <div className="welcome-banner-section">
                                  <div className="welcome-wrap text-center">
                                    <div className="section-title1 text-center">
                                      <span>(Since-1994)</span>
                                      <h2>Welcome To Arjan Motors</h2>
                                    </div>
                                    <div class="row" style={{ marginTop: '30px' }}>
                                      <div class="col-lg-6 col-md-12">
                                        <div className="author-area">
                                          <img
                                            decoding="async"
                                            className='frame'
                                            src="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                                            alt="author signature"
                                          />
                                          <h6>Karan Solanki</h6>
                                          <span>(CEO &amp; Founder)</span>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-12">
                                        <div className="welcome-content">
                                          <p>
                                            Established in 1994, Arjan Motors stands as the epitome of luxury car excellence at <b>6/4A Ground Pkt-B, Ashok Vihar, New Delhi, Delhi 11052, India</b>. With a rich history spanning 25 years, we're the trusted choice for Delhi's discerning customers.
                                            <br /><br />
                                            Our unwavering commitment to customer satisfaction has fueled our growth. Enjoy a seamless experience buying or selling pre-owned luxury vehicles from our diverse inventory, featuring renowned brands like Range Rover, Porsche, Lexus, Mercedes, BMW, and Audi.
                                            <br />
                                            <br />
                                            Behind our success is a team of seasoned professionals, each contributing years of expertise to realize our common vision. Looking ahead, Arjan Motors envisions expanding our offerings to reach an even broader clientele.
                                            <br />
                                            When you choose Arjan Motors, you're not merely selecting a dealership; you're engaging with a legacy of trust, quality, and an unrelenting pursuit of excellence in the world of luxury automobiles.
                                          </p>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  className="elementor-section elementor-top-section elementor-element elementor-element-a40a348 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="a40a348"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-row">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-157b9ed"
                        data-id="157b9ed"
                        data-element_type="column"
                      >
                        <div className="elementor-column-wrap elementor-element-populated" style={{ marginTop: '50px' }}>
                          <div className="elementor-widget-wrap">
                            <div
                              className="elementor-element elementor-element-4a454dc elementor-widget elementor-widget-arjan_gallery"
                              data-id="4a454dc"
                              data-element_type="widget"
                              data-widget_type="arjan_gallery.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="arjan-gallery">
                                  <div className="container">
                                    <div className="row mb-50">
                                      <div className="col-lg-12">
                                        <div className="section-title1 text-center">
                                          <h2>Our Gallery</h2>
                                        </div>
                                      </div>
                                    </div>
                                    {isFetching && <div style={{margin:'auto', display:'table'}}><Spinner/></div>}
                                    <div className="row g-4" style={{ marginBottom: '50px' }}>
                                      {uploadedImages.map((item) => (
                                        <div className="col-lg-4 col-sm-6">
                                          <div className="gallery-item">
                                            <img
                                              decoding="async"
                                              className="img-fluid"
                                              src={item.url}
                                              alt="gallery"
                                              height={200}
                                              style={{background:'whitesmoke', border:'7px solid #2A2B30', padding:'7px'}}
                                            />
                                            <a
                                              href="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                                              data-fancybox="gallery"
                                              className="gallary-item-overlay"
                                            />
                                          </div>
                                        </div>
                                      ))}
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default About;