import React from 'react';
import { Link } from 'react-router-dom';

const FaqPage = () => {
    return (
        <>
            <div className="inner-page-banner">
                <div className="banner-wrapper">
                    <div className="container-fluid">
                        <ul id="breadcrumb-list" className="breadcrumb-list">
                            <li className="breadcrumb-item">
                                <Link to="/"> Home </Link>
                            </li>
                            <li className="active">FAQ’s</li>
                        </ul>
                        <div className="banner-main-content-wrap">
                            <div className="row">
                                <div className="col-xl-6 col-lg-7  d-flex align-items-center">
                                    <div className="banner-content">
                                        <h1>FAQ’s</h1>
                                        {/* <div className="customar-review">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <div className="review-top">
                                                            <div className="logo">
                                                                <img
                                                                    src="../content/uploads/2023/09/trstpilot-logo.svg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="star">
                                                                <img
                                                                    src="../content/uploads/2023/10/trustpilot-star.svg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <ul>
                                                                <li>
                                                                    Trust Rating <span>5.0</span> | <span>2348</span>
                                                                    Reviews
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <div className="review-top">
                                                            <div className="logo">
                                                                <img
                                                                    src="../content/uploads/2023/09/google-logo.svg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="star">
                                                                <img
                                                                    src="../content/uploads/2023/10/star.svg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <ul>
                                                                <li>
                                                                    Trust Rating <span>4.5</span> | <span>5841</span>
                                                                    Reviews
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-5 d-lg-flex d-none align-items-center justify-content-end">
                                    <div className="banner-img">
                                        <img
                                            src="../content/plugins/drivco-core/inc/theme-options/images/breadcrumb/breadcrumb-right-img.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-wrapper faq-bottom-section" >
                <div className="page-content-wrapper">
                    <div
                        data-elementor-type="wp-page"
                        data-elementor-id={815}
                        className="elementor elementor-815"
                    >
                        <div className="elementor-inner">
                            <div className="elementor-section-wrap">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div className="elementor-image">
                                                <img
                                                    fetchpriority="high"
                                                    decoding="async"
                                                    width='100%'
                                                    src="../wp-content/uploads/2023/10/faq-img.png"
                                                    className="attachment-full size-full wp-image-888"
                                                    alt=""
                                                    srcSet="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/10/faq-img.png 437w, https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/10/faq-img-177x300.png 177w, https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/10/faq-img-300x508.png 300w"
                                                    sizes="(max-width: 437px) 100vw, 437px"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-7'>
                                            <div
                                                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-53e740a"
                                                data-id="53e740a"
                                                data-element_type="column"
                                            >
                                                <div className="elementor-column-wrap elementor-element-populated">
                                                    <div className="elementor-widget-wrap">
                                                        <div
                                                            className="elementor-element elementor-element-cdd8365 elementor-widget elementor-widget-drivco_faq"
                                                            data-id="cdd8365"
                                                            data-element_type="widget"
                                                            data-widget_type="drivco_faq.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="faq-page-wrap">
                                                                    <div className="faq-area">
                                                                        <div className="section-title-and-filter mb-40">
                                                                            <div className="section-title">
                                                                                <h4> FAQ’s &amp; Latest Answer</h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className="faq-wrap">
                                                                            <div
                                                                                className="accordion accordion-flush"
                                                                                id="accordionFlushExample"
                                                                            >
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading0"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse0"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse0"
                                                                                        >
                                                                                           What types of cars do you sell at Arjan Motors?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse0"
                                                                                        className="accordion-collapse collapse show"
                                                                                        aria-labelledby="flush-heading0"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        At Arjan Motors, we specialize in offering a diverse range of vehicles, including luxury cars, SUVs, sedans, and performance vehicles from renowned brands like Mercedes-Benz, BMW, Audi, and more.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading1"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse1"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse1"
                                                                                        >
                                                                                           Can I trade in my current vehicle at Arjan Motors?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse1"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading1"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Absolutely! We accept trade-ins of all makes and models. Our team will assess the value of your vehicle and offer competitive trade-in deals to help you upgrade to your dream car hassle-free.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading2"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse2"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse2"
                                                                                        >
                                                                                            Do you provide financing options for purchasing vehicles?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse2"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading2"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Yes, we understand that financing is an essential aspect of purchasing a car. We offer flexible financing solutions tailored to your needs, including competitive interest rates and convenient repayment terms. Our finance experts are here to assist you throughout the process.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading3"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse3"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse3"
                                                                                        >
                                                                                          Are your pre-owned vehicles inspected and certified?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse3"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading3"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Yes, all our pre-owned vehicles undergo a rigorous inspection process by our certified technicians to ensure they meet our high standards of quality and safety. Additionally, we offer certified pre-owned programs for added peace of mind.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading4"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse4"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse4"
                                                                                        >
                                                                                            Can I schedule a test drive before making a purchase?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse4"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading4"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Of course! We encourage all our customers to schedule a test drive to experience the performance, comfort, and features of our vehicles firsthand. Simply contact us, and we'll arrange a convenient time for you.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading5"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse5"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse5"
                                                                                        >
                                                                                            What warranties do you offer on your vehicles?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse5"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading5"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Our new vehicles come with manufacturer warranties that provide coverage for a specified period. Additionally, we offer extended warranty options for both new and pre-owned vehicles to provide added protection and assurance.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item">
                                                                                    <h5
                                                                                        className="accordion-header"
                                                                                        id="flush-heading6"
                                                                                    >
                                                                                        <button
                                                                                            className="accordion-button collapsed"
                                                                                            type="button"
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target="#flush-collapse6"
                                                                                            aria-expanded="false"
                                                                                            aria-controls="flush-collapse6"
                                                                                        >
                                                                                            Do you provide after-sales service and maintenance?{" "}
                                                                                        </button>
                                                                                    </h5>
                                                                                    <div
                                                                                        id="flush-collapse6"
                                                                                        className="accordion-collapse collapse "
                                                                                        aria-labelledby="flush-heading6"
                                                                                        data-bs-parent="#accordionFlushExample"
                                                                                    >
                                                                                        <div className="accordion-body">
                                                                                        Yes, our commitment to customer satisfaction extends beyond the sale. Our state-of-the-art service center is equipped with the latest technology and staffed by trained technicians who provide comprehensive maintenance, repairs, and genuine parts for your vehicle.{" "}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container text-center faq-bottom-section" style={{}}>
                                        <div className="">
                                            <div className="section-title mb-20">
                                                <h4>For More Questions</h4>
                                            </div>
                                            <div class="form-inner">
                                                <Link to="/contact-us">
                                                    <button type="submit"
                                                        class="primary-btn3">Contact Us</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FaqPage;