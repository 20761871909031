import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import LoadingSpinner from '../../dashboard/utilities/LoadingSpinner/LoadingSpinner';
import ProductCard from './ProductCard/ProductCard';
import InnerBanner from './InnerBanner';
import Filters from './Filters';
import { restApiUri } from '../../constants';
import useQueryParams from '../../util/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound404 from '../../others/NotFound404/NotFound404';

const Products = () => {
  const navigation = useNavigate();
  const myParams = useQueryParams();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6); // Adjust based on desired initial view
  const [specialPage, setSpecialPage] = useState(true)
  const availableCarsRef = useRef(null);
  const prevParams = useRef(myParams);

  const observerRef = useRef(null);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await fetch(`${restApiUri}/products${window.location.search}`);
    const data = await res.json();
    setProducts(Array.isArray(data) ? data : data.results);
    setIsLoading(false);
  };

  const handleIntersection = (entries) => {
    // Access specific properties for debugging:
    const [entry] = entries;
    // Implement your logic based on intersection details (e.g., trigger pagination)
    if (entry.isIntersecting) {
      const nextPage = currentPage + 1;
      const specialCase = (products.length - (nextPage * pageSize) < pageSize) && specialPage
      console.log((nextPage * pageSize), products.length)
      if ((nextPage * pageSize) < products.length ||
        ((nextPage * pageSize) > products.length) &&
        specialCase) {
        setCurrentPage(nextPage);
      }
      else {
        setIsLoading(false)
      }
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, products.length);
      if ((startIndex > endIndex) && specialPage) {
        setSpecialPage(false)
      }
    }
  };

  useEffect(() => {
    // Check if the query parameters have changed
    const paramsChanged = Object.keys(myParams).some(
      (key) => myParams[key] !== prevParams.current[key]
    );
    if (paramsChanged || JSON.stringify(myParams) !== JSON.stringify(prevParams.current)) {
      fetchData();
      setCurrentPage(1); // Reset page for new filters
      setSpecialPage(true)
      prevParams.current = myParams; // Update the previous params
    }
  }, [myParams]); // Dependency on myParams

  const { pathname } = useLocation();

  useEffect(() => {
    // const canControlScrollRestoration = 'scrollRestoration' in window.history
    // if (canControlScrollRestoration) {
    //   window.history.scrollRestoration = 'manual';
    // }
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();

  }, []); // Fetch data initially


  useEffect(() => {
    observerRef.current = document.querySelector('.load-more-sentinel');
    setCurrentPage(1)
  }, [])

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(handleIntersection)
    if (observerRef.current) {
      intersectionObserver.unobserve(observerRef.current);
    }
    if (observerRef.current) {
      intersectionObserver.observe(observerRef.current)
    }
    return () => intersectionObserver.disconnect();
  }, [currentPage])

  const getVisibleProducts = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, products.length);
    return products.slice(0, endIndex)
  };

  const handleChipRemove = (key) => {
    delete myParams[key];
    setCurrentPage(1)
    setSpecialPage(true)
    navigation({
      pathname: `/cars`,
      search: new URLSearchParams(myParams).toString(),
    });
  };

  // useEffect to scroll to the element when component mounts
  useEffect(() => {
    const scrollToAvailableCars = window.location.href.includes('#cars-section')
    if (availableCarsRef.current && scrollToAvailableCars) {
      setTimeout(() => {
        availableCarsRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500)
    }
  }, []);

  return (
    <div className="single-category-page sec-mar" style={{ marginTop: '0px' }}>
      <InnerBanner />
      <div className="container">
        <Filters />
      </div>
      <div className="container avialable-cars" >
        <div className="w-full container">
          <div className="row flex flex-col md:flex-row" style={{ width: '100%' }}>
            <div className="col-12 md:col-6 xl:col-6">
              <h2
                style={{ marginTop: '15px' }}
                // id="available-cars-heading"
                ref={availableCarsRef}
                className="text-2xl font-bold"
              >
                Available <span className="text-[#D7B65D]">cars</span>  ({products.length})
              </h2>
            </div>
            <div className="col-12 md:col-6 mt-4 md:mt-0 md:text-left text-center d-flex gap-2 overflow-auto filter-chips" style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}>
              {Object.keys(myParams).map((key) => (
                <div data-dismissible="chip" style={{ color: 'black', background: 'white', border: '1px solid silver' }} className="relative grid select-none items-center whitespace-nowrap rounded-lg py-1.5 px-3 font-sans text-xs font-bold text-white">
                  <span className="mr-5 text-black">{myParams[key]}</span>
                  <button data-dismissible-target="chip" onClick={() => handleChipRemove(key)} className="!absolute top-2/4 right-1 mx-px h-5 max-h-[32px] w-5 max-w-[32px] -translate-y-2/4 select-none rounded-md text-center align-middle font-sans text-xs font-medium uppercase text-black transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                    <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <div className="row g-4">
          {isLoading && <LoadingSpinner />}
          {!isLoading && products.length === 0 && <NotFound404 />}
          {getVisibleProducts().map((product) => (
            <div className="col-xl-3 col-lg-4 col-md-6" key={product._id}>
              <ProductCard product={product} key={product._id} />
            </div>
          ))}

          <div className="load-more-sentinel">
            {(currentPage * pageSize) < products.length && (
              <LoadingSpinner />
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Products;