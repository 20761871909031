import { useEffect, useState } from "react";
// import brandsModals from "../../../src/util/brands-models.json";
import { useParams, useNavigate } from "react-router-dom";
import useQueryParams from "../../util/utils";
import { Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { restApiUri } from "../../constants";

var getYears = () => {
  // Dynamically generate the list of years from today's year to 20 years back
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 25 }, (_, index) => currentYear - index);
  return [years[years.length - 1], [years[0]]];
};
const Filters = ({ isHome }) => {
  const defaultYears = getYears();
  const myParams = useQueryParams();
  const [brandsModals, setbrandModels] = useState([]);
  const [carBrand, setCarBrand] = useState(myParams?.make);
  const [selectedCarModels, setSelectedCarModels] = useState([]);
  const [model, setModel] = useState("");
  const [driven, setDriven] = useState(myParams?.driven);
  const [owner, setOwner] = useState("");
  const [fuel, setFuel] = useState("");
  const [bodyType, setBodyType] = useState(myParams?.bodyType);
  const [tranmission, setTransmission] = useState("");
  const [yearsList, setYearList] = useState([]);
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [range, setRange] = useState(defaultYears);
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handlebodyType = (selectedBodyType) => {
    setBodyType(selectedBodyType);
    console.log(selectedBodyType);
    closeModal();
  };

  console.log(myParams, "selected params");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Fetch brands models data
    fetch(`${restApiUri}/brands-model`)
      .then((response) => response.json())
      .then((data) => {
        setbrandModels(data);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching brands models:", error));
  }, []);

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleBrandSelection = (carBrand) => {
    setCarBrand(carBrand);
    const selectedModals = brandsModals.filter(
      (brand) => brand.brand === carBrand
    )[0];
    setSelectedCarModels(selectedModals?.models, "filtered Cars Modals");
    if (selectedModals?.models.length) {
      setModel(selectedModals?.models[0]);
    }
  };

  useEffect(() => {
    if (!myParams.make && carBrand?.length > 0) {
      setCarBrand("");
    }
    if (!myParams.model && model?.length > 0) {
      console.log("modal updated,", model);
      // handleBrandSelection('');
      // setModel('');
    }
    if (!myParams.driven && driven?.length > 0) {
      setDriven("");
    }
    if (!myParams.year && range?.length > 0) {
      setYearList(getYears());
      setRange(getYears());
    }
    if (!myParams.owner && owner?.length > 0) {
      setOwner("");
    }
    if (!myParams.fuel && fuel?.length > 0) {
      setFuel("");
    }
    if (!myParams.tranmission && tranmission?.length > 0) {
      setTransmission("");
    }
    if (!myParams.bodyType && bodyType?.length > 0) {
      setBodyType("");
    }
    if (myParams.make) {
      handleBrandSelection(myParams.make);
      setModel(myParams?.model);
    }
    if (myParams.make === "") {
      setModel("");
    }
    // if(selectedCarModels && selectedCarModels.length>0){
    //   setModel(selectedCarModels[0])
    // }
  }, [
    myParams.bodyType,
    myParams.make,
    myParams.model,
    myParams.driven,
    myParams.owner,
    myParams.fuel,
    myParams.tranmission,
    myParams.year,
  ]);

  useEffect(() => {
    setYearList(getYears());
    setRange(getYears());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e, "search");
    const queryParams = {};
    if (carBrand && carBrand.length > 0) {
      queryParams["make"] = carBrand;
    }
    if (model?.length > 0) {
      queryParams["model"] = model;
    }
    if (String(range) !== String(defaultYears) && range?.length > 0) {
      queryParams["year"] = range;
    }
    if (driven?.length > 0) {
      queryParams["driven"] = driven;
    }
    if (owner?.length > 0) {
      queryParams["owner"] = owner;
    }
    if (fuel?.length > 0) {
      queryParams["fuel"] = fuel;
    }
    if (bodyType?.length > 0) {
      queryParams["bodyType"] = bodyType;
    }
    if (tranmission?.length > 0) {
      queryParams["transmission"] = tranmission;
    }
    console.log(window.location);
    navigation({
      pathname: `/cars/`,
      search: new URLSearchParams(queryParams).toString(),
      hash: '#cars-section',  // Include the hash fragment like this
    });

  };

  return (
    <div
      className="product-search-area mb-50"
      style={{
        zIndex: 2,
        position: "relative",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "15px",
      }}
    >
      <div className="">
        <form>
          <h2
            style={{ fontWeight: "bold", marginBottom: "10px", color: "white" }}
          >
            Search Inventory
          </h2>
          <div
            className={`row row-cols-xl-${isHome ? 3 : 3
              } row-cols-md-3 row-cols-sm-2 row-cols-1 g-3 justify-content-center`}
          >
            <div className="col">
              <div className="form-inner">
                <label>Make</label>
                <select
                  name="vehicle_condition"
                  className={
                    myParams.make > 0 ? "hello form-control" : "form-control"
                  }
                  value={carBrand}
                  onChange={(e) => handleBrandSelection(e.target.value)}
                >
                  <option value="">
                    {isLoading ? "Loading..." : "Select Make"}
                  </option>
                  {brandsModals.map((brand, index) => (
                    <option value={brand?.brand}>{brand?.brand}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col">
              <div className="form-inner">
                <label>Model*</label>
                <select
                  name="color"
                  className="form-control"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value="">
                    {isLoading ? "Loading..." : "Select Model"}
                  </option>
                  {selectedCarModels &&
                    selectedCarModels?.map((modal, index) => (
                      <option value={modal}>{modal}</option>
                    ))}
                </select>
              </div>
            </div>
            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <div style={{ width: "100%" }}>
                    <Typography
                      sx={{ color: "white" }}
                      id="range-slider"
                      gutterBottom
                    >
                      Year {range[0]} - {range[1]}
                    </Typography>
                    <Slider
                      sx={{
                        "& .MuiSlider-thumb": {
                          color: "#D7B65D",
                        },
                        "& .MuiSlider-track": {
                          color: "#D7B65D",
                        },
                        "& .MuiSlider-rail": {
                          color: "#acc4e4",
                        },
                        "& .MuiSlider-active": {
                          color: "green",
                        },
                      }}
                      value={range}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      min={getYears()[0]}
                      max={getYears()[1]}
                    />
                  </div>
                </div>
              </div>
            )}
            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <label>Max KMS Driven</label>
                  <select
                    name="vehicle_model"
                    value={driven}
                    className="form-control"
                    onChange={(e) => setDriven(e.target.value)}
                  >
                    <option value="">Select Max KMS Driven</option>
                    <option value="< 5000"> {"< 5000"}</option>
                    <option value="5000-20000">50,00-20,000</option>
                    <option value="20000-50000">20,000-50,000</option>
                    <option value="50000-100000">50,000-1,00,000</option>
                    <option value="100000-300000">1,00,000-3,00,000</option>
                    <option value="300000-500000">3,00,000-5,00,000</option>
                  </select>
                </div>
              </div>
            )}

            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <label>Owner Serial*</label>
                  <select
                    name="color"
                    value={owner}
                    className="form-control"
                    onChange={(e) => setOwner(e.target.value)}
                  >
                    <option value="">Select Owner Serial</option>
                    <option value="First Owner"> First Owner</option>
                    <option value="Second Owner"> Second Owner</option>
                    <option value="Third Owner"> Third Owner</option>
                    <option value="Fourth Owner"> Fourth Owner</option>
                    <option value="Fifth Owner"> Fifth Owner</option>
                  </select>
                </div>
              </div>
            )}
            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <label>Fuel Type*</label>
                  <select
                    name="color"
                    value={fuel}
                    className="form-control"
                    onChange={(e) => setFuel(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="Petrol"> Petrol</option>
                    <option value="Diesel"> Diesel</option>
                    <option value="Hybrid"> Hybrid</option>
                    <option value="CNG"> CNG</option>
                    <option value="Diesel/Hybrid"> Diesel/Hybrid</option>
                    <option value="Petrol/Hybrid"> Petrol/Hybrid</option>
                  </select>
                </div>
              </div>
            )}
            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <label>Body Type*</label>
                  <h1 onClick={() => openModal()} className="bodytype-heading">
                    {bodyType ? bodyType : "Select Body Type"}
                  </h1>
                  {/* <select
                    name="color"
                    className="form-control"
                    onChange={(e) => setBodyType(e.target.value)}
                  >
                    <option value="">Select Body Type</option>
                    <option defaultValue="coupe" >Coupe</option>
                    <option defaultValue="muv/suv" >MUV/SUV</option>
                    <option defaultValue="sports-car">Sports Car</option>
                    <option defaultValue="mini-van">Mini Van</option>
                    <option defaultValue="convertible">Convertible</option>
                    <option defaultValue="hatchback">Hatchback</option>
                  </select> */}
                </div>
              </div>
            )}
            {!isHome && (
              <div className="col">
                <div className="form-inner">
                  <label>Transmission*</label>
                  <select
                    name="color"
                    className="form-control"
                    value={tranmission}
                    onChange={(e) => setTransmission(e.target.value)}
                  >
                    <option value="">Select Transmission*</option>
                    <option value="Manual"> Manual</option>
                    <option value="Automatic"> Automatic</option>
                  </select>
                </div>
              </div>
            )}

            <div className="col d-flex align-items-end">
              <div className="form-inner">
                <button
                  className="primary-btn3"
                  onClick={(e) => handleSubmit(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={14}
                    viewBox="0 0 14 14"
                  >
                    <path d="M10.2746 9.04904C11.1219 7.89293 11.5013 6.45956 11.3371 5.0357C11.1729 3.61183 10.4771 2.30246 9.38898 1.36957C8.30083 0.436668 6.90056 -0.050966 5.46831 0.00422091C4.03607 0.0594078 2.67747 0.653346 1.66433 1.66721C0.651194 2.68107 0.0582276 4.04009 0.00406556 5.47238C-0.0500965 6.90466 0.43854 8.30458 1.37222 9.39207C2.30589 10.4795 3.61575 11.1744 5.03974 11.3376C6.46372 11.5008 7.89682 11.1203 9.05232 10.2722H9.05145C9.07769 10.3072 9.10569 10.3405 9.13719 10.3729L12.5058 13.7415C12.6699 13.9057 12.8924 13.9979 13.1245 13.998C13.3566 13.9981 13.5793 13.906 13.7435 13.7419C13.9076 13.5779 13.9999 13.3553 14 13.1232C14.0001 12.8911 13.908 12.6685 13.7439 12.5043L10.3753 9.13566C10.344 9.104 10.3104 9.07562 10.2746 9.04904ZM10.5004 5.68567C10.5004 6.31763 10.3759 6.9434 10.1341 7.52726C9.89223 8.11112 9.53776 8.64162 9.0909 9.08849C8.64403 9.53535 8.11352 9.88983 7.52967 10.1317C6.94581 10.3735 6.32003 10.498 5.68807 10.498C5.05611 10.498 4.43034 10.3735 3.84648 10.1317C3.26262 9.88983 2.73211 9.53535 2.28525 9.08849C1.83838 8.64162 1.48391 8.11112 1.24207 7.52726C1.00023 6.9434 0.875753 6.31763 0.875753 5.68567C0.875753 4.40936 1.38276 3.18533 2.28525 2.28284C3.18773 1.38036 4.41177 0.873346 5.68807 0.873346C6.96438 0.873346 8.18841 1.38036 9.0909 2.28284C9.99338 3.18533 10.5004 4.40936 10.5004 5.68567Z"></path>
                  </svg>
                  Search{" "}
                </button>
              </div>
            </div>
          </div>
        </form>
        {isOpen && (
          <div className="modal-single-car-overlay">
            <div className="bodytype-car">
              <div className="modal-header">
                <h2>Body Types</h2>
                <i
                  class="bi bi-x-circle"
                  style={{ fontSize: "25px", color: "#fff" }}
                  onClick={closeModal}
                ></i>
              </div>
              <div className="container" style={{ padding: "10px 20px" }}>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Coupe")}
                >
                  {" "}
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/car.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Coupe
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Sedan")}
                >
                  {" "}
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/car.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Sedan
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("MUV/SUV")}
                >
                  {" "}
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/suv.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  MUV/SUV
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Sports Car")}
                >
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/sport-car.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Sports Car
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Mini Van")}
                >
                  {" "}
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/mini-van.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Mini Van
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Convertible")}
                >
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/convertible-car.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Convertible
                </h1>
                <h1
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => handlebodyType("Hatchback")}
                >
                  <img
                    decoding="async"
                    src="../content/uploads/2023/09/hatchback-car-1.png"
                    alt="logo-icon"
                    width="30px"
                  />
                  Hatchback
                </h1>

                <div className="text-center">
                  {/* <button
                    onClick={(e) => handleTestDrive(e)}
                    className="next-btn mb-3"
                  >
                    Request
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
