import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaCar,
  FaShoppingCart,
  FaSignOutAlt,
  FaTachometerAlt,
  FaUsers,
  FaWindowClose,
} from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthProvider/AuthProvider';
import useAdmin from '../../../hooks/useAdmin/useAdmin';
import useSeller from '../../../hooks/useSeller/useSeller';

const DashboardSideNavbar = ({ hidden }) => {
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin, isAdminLoading] = useAdmin(user?.uid);
  const [isSeller, isSellerLoading] = useSeller(user?.uid);
  const [showSidebar, setShowSidebar] = useState(true);

  const handleLogOut = () => {
    logOut()
      .then((res) => {
        toast.success('Logged out!');
      })
      .catch((err) => {
        console.error(err);
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    const handleWindowResize = () => {
      // Show sidebar if the window width is greater than or equal to 768px (typical tablet width)
      setShowSidebar(window.innerWidth >= 768);
    };

    // Event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Initial check on component mount
    handleWindowResize();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="relative h-screen flex">
      {/* Sidebar */}

      <aside
        className={`${showSidebar ? 'block' : 'hidden lg:block'
          } z-20 fixed h-full top-0 left-0 pt-16 flex flex-col w-64 bg-white border-r border-gray-200 transition-all duration-300 ease-in-out overflow-y-auto`}
        aria-label="Sidebar"
      >
        <div style={{ margin: '20px 10px 5px 15px' }}>
          <p style={{ color: 'grey' }}> <i class="ph-bold ph-user-circle"></i>&nbsp;&nbsp;<strong>{user?.displayName}</strong></p>
        </div>

        <div className="overflow-y-auto" >
          <button
            style={{ position: 'absolute', right: 10, top: 80 }}
            onClick={() => setShowSidebar(false)}
            className="lg:hidden text-gray-700 focus:outline-none focus:text-gray-500"
          >
            <FaWindowClose className="text-xl" />
          </button>
          <nav className="mt-4">
            <ul className="">
              <li>
                <NavLink
                  to="/dashboard"
                  className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                >
                  <i class="ph-bold ph-gauge"></i>&nbsp;&nbsp;
                  <span>Dashboard</span>
                </NavLink>
              </li>

              {isSeller || true && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/add-product"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-car-profile"></i>&nbsp;&nbsp;
                      <span>Add Vehicle</span>
                    </NavLink>
                  </li>
                  {/* Add other seller-related links */}
                </>
              )}
              {isSeller || true && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/products"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-car"></i>&nbsp;&nbsp;
                      <span>My Vehicles</span>
                    </NavLink>
                  </li>
                  {/* Add other seller-related links */}
                </>
              )}

              {isAdmin && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/sellers"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-users-three"></i>&nbsp;&nbsp;
                      <span>Sellers Management</span>
                    </NavLink>
                  </li>
                  {/* Add other admin-related links */}
                </>
              )}
              {isAdmin && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/brands-model"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-gear"></i>&nbsp;&nbsp;
                      <span>Brands & Model</span>
                    </NavLink>
                  </li>
                  {/* Add other admin-related links */}
                </>
              )}
              {isAdmin && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/feedbacks"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-gear"></i>&nbsp;&nbsp;
                      <span>Feedback</span>
                    </NavLink>
                  </li>
                  {/* Add other admin-related links */}
                </>
              )}
              {isAdmin && (
                <>
                  <li>
                    <NavLink
                      to="/dashboard/banners-gallery"
                      className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                    >
                      <i class="ph-bold ph-gear"></i>&nbsp;&nbsp;
                      <span>Banners & Gallery</span>
                    </NavLink>
                  </li>
                  {/* Add other admin-related links */}
                </>
              )}
              <li>
                <button
                  style={{ width: '100%' }}
                  onClick={handleLogOut}
                  className="flex items-center p-3 text-gray-700 hover:bg-gray-100"
                >
                  <i class="ph-bold ph-sign-out"></i>&nbsp;&nbsp;
                  <span>Sign Out</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      {/* Hamburger for mobile view */}
      <div className="lg:hidden fixed top-0 left-0 z-30 w-full bg-white border-b border-gray-200" style={{ display: 'inline-flex', height: '70px' }}>
        <button
          onClick={() => setShowSidebar(!showSidebar)}
          className="p-3 focus:outline-none"
        >
          <svg
            className="h-6 w-6 fill-current text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm16 4H4v2h16v-2z"
            />
          </svg>
        </button>
        <Link to="/">
          <h2 style={{ margin: '20px', fontWeight: 'bold' }}>Arjan Motors</h2>
        </Link>
        <div className="flex items-center" style={{ marginLeft: 'auto' }}>
          <Link to="/cars" style={{ width: '100px' }} className="text-white bg-[#191D25] hover:bg-[#191D25] px-2 py-1 text-center items-center mr-3">
            Visit Store
          </Link>
        </div>
      </div>

    </div>
  );
};

export default DashboardSideNavbar;
