import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider/AuthProvider";
import "./Navbar.css";

const Navbar = () => {
  const { user, logOut } = useContext(AuthContext);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const navigation = useNavigate();

  const handleLogOut = () => {
    logOut()
      .then((res) => {
        toast.success("Logged out!");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Something happened wrong!");
      });
  };

  // const handleSubmit = () => {
  //   console.log(search, "searcg items");
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    const queryParams = {};
    if (search.length > 0) {
      queryParams["search"] = search;
    }
    navigation({
      pathname: `/cars`,
      search: new URLSearchParams(queryParams).toString(),
    });
  };

  const menuItems = (
    <>
      <li>
        <NavLink className="rounded btn-sm" to="/">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink className="rounded btn-sm" to="/cars">
          All Cars
        </NavLink>
      </li>
      {categories
        .filter((item) => item.menuStatus === "active")
        .map((item, i) => (
          <li key={i}>
            <NavLink className="rounded btn-sm " to={`/category/${item.slug}`}>
              {item.name}
            </NavLink>
          </li>
        ))}
      {brands
        .filter((item) => item.menuStatus === "active")
        .map((item, i) => (
          <li key={i}>
            <NavLink className="rounded btn-sm " to={`/brand/${item.slug}`}>
              {item.name}
            </NavLink>
          </li>
        ))}
      <li>
        <NavLink className="rounded btn-sm " to="/blog">
          Blog
        </NavLink>
      </li>
      <li>
        <NavLink className="rounded btn-sm " to="/about">
          About
        </NavLink>
      </li>
      <li>
        <NavLink className="rounded btn-sm " to="/contact">
          Contact
        </NavLink>
      </li>
    </>
  );

  return (
    <>
      {/* TOP NAVBAR */}
      <div className="top-bar sticky-header">
        <div className="company-logo">
          <Link className="font-bold text-xl" style={{ color: "white" }} to="/">
            Arjan Motors
          </Link>
        </div>
        <div className="search-area">
          <form>
            <div className="form-inner">
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by Make or Model"
              />
              {/* Change 'vehicle' to your custom post type name */}
              <button className="primary-btn3" onClick={(e) => handleSubmit(e)}>
                <i className="bi bi-search" />
              </button>
            </div>
          </form>
        </div>
        <div className="topbar-right">
          <ul>
            <li className="d-xl-flex d-none">
              <button
                style={{
                  maxWidth: "238px",
                  marginRight: "15px",
                  color:'silver'
                }}
                className="sell-btn"
                type="button"
              >
                <i
                  className="bi bi-geo-alt"
                  style={{ fontSize: "30px", color: "silver" }}
                  aria-hidden="true"
                ></i>
                <span>
                  6/4A Ground Pkt-B, Ashok Vihar, New Delhi, Delhi 11052, India{" "}
                </span>
              </button>
            </li>
            <li>
              {user && user.uid ? (
                <>
                  {/* <ul className="menu menu-horizontal p-0 font-medium" style={{ gap: '4px' }}>
                    <li><NavLink to="/dashboard" className='rounded bg-white drop-shadow btn-sm'>Dashbaord</NavLink></li>
                  </ul> */}
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0}>
                      <div class="avatar">
                        <div class="w-10 rounded-full ring ring-offset-base-50 ring-offset-10">
                          <img
                            className="rounded-full"
                            src={user.photoURL}
                            alt={user.displayName}
                          />
                        </div>
                      </div>
                    </label>
                    <ul
                      style={{ zIndex: 2000, border: "1px solid silver" }}
                      className="menu dropdown-content"
                    >
                      <div class="menu1" tabIndex={0}>
                        <ul>
                          <li>
                            <a href="#">
                              <i class="ph-bold ph-user"></i>&nbsp;
                              <span className="flex-col items-start	cursor-default">
                                <span className="font-medium">
                                  {user.displayName}
                                </span>
                                {/* <span className='small text-gray-500'>{user.email}</span> */}
                              </span>
                            </a>
                          </li>
                          <li>
                            <NavLink to="/dashboard">
                              <i class="ph-bold ph-gauge"></i>&nbsp;Dashboard
                            </NavLink>
                          </li>
                          <li>
                            <a href="#">
                              <i class="ph-bold ph-sign-out"></i>&nbsp;
                              <span onClick={() => handleLogOut()}>Logout</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  </div>
                </>
              ) : (
                <li>
                  <NavLink
                    className="primary-btn1"
                    style={{ background: "#D7B65D" }}
                    to="/login"
                  >
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.4311 12.759C15.417 11.4291 16 9.78265 16 8C16 3.58169 12.4182 0 8 0C3.58169 0 0 3.58169 0 8C0 12.4182 3.58169 16 8 16C10.3181 16 12.4058 15.0141 13.867 13.4387C14.0673 13.2226 14.2556 12.9957 14.4311 12.759ZM13.9875 12C14.7533 10.8559 15.1999 9.48009 15.1999 8C15.1999 4.02355 11.9764 0.799983 7.99991 0.799983C4.02355 0.799983 0.799983 4.02355 0.799983 8C0.799983 9.48017 1.24658 10.8559 2.01245 12C2.97866 10.5566 4.45301 9.48194 6.17961 9.03214C5.34594 8.45444 4.79998 7.49102 4.79998 6.39995C4.79998 4.63266 6.23271 3.19993 8 3.19993C9.76729 3.19993 11.2 4.63266 11.2 6.39995C11.2 7.49093 10.654 8.45444 9.82039 9.03206C11.5469 9.48194 13.0213 10.5565 13.9875 12ZM13.4722 12.6793C12.3495 10.8331 10.3188 9.59997 8.00008 9.59997C5.68126 9.59997 3.65049 10.8331 2.52776 12.6794C3.84829 14.2222 5.80992 15.2 8 15.2C10.1901 15.2 12.1517 14.2222 13.4722 12.6793ZM8 8.79998C9.32551 8.79998 10.4 7.72554 10.4 6.39995C10.4 5.07444 9.32559 3.99992 8 3.99992C6.6744 3.99992 5.59997 5.07452 5.59997 6.40003C5.59997 7.72554 6.67449 8.79998 8 8.79998Z"
                      />
                    </svg>
                    Login{" "}
                  </NavLink>
                </li>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
