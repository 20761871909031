import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { restApiUri } from '../../constants';
import toast from 'react-hot-toast';

const Feedbacks = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFeedback = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`${restApiUri}/feedback`, {
        headers: {
          authorization: `bearer ${localStorage.getItem("access-token")}`,
        },
      });
      const data = await res.json();
      console.log(data, 'data==>')
      setFeedbackData(data);
    } catch (error) {
      console.error(error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (feedbackId) => {
    const status = window.confirm('You are approving this feedback!')
    if (status) {
      try {
        await fetch(`${restApiUri}/feedback/${feedbackId}/approve`, {
          method: "PUT",
          headers: {
            authorization: `bearer ${localStorage.getItem("access-token")}`,
          },
        })
        fetchFeedback(); // Re-fetch data after approval
      } catch (error) {
        toast.error(String(error).replace('AxiosError:', ''))
        console.error(error);
        // setError(error.message); // Update error state
      }
    }
  };

  const handleDelete = async (feedbackId) => {
    if (window.confirm('Are you sure you want to delete this feedback?')) {
      try {
        await axios.delete(`${restApiUri}/feedback/${feedbackId}`, {
          headers: {
            authorization: `bearer ${localStorage.getItem('access-token')}`
          }
        });
        fetchFeedback(); // Re-fetch data after deletion
      } catch (error) {
        toast.error(String(error).replace('AxiosError:', ''))
        console.error(error);
        // setError(error.message); // Update error state
      }
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  return (
    <div className="feedback-container">
      <h2>Feedback Management</h2>
      {error && <p className="error">{error}</p>}
      {isLoading ? (
        <p>Loading feedback...</p>
      ) : (
        <FeedbackTable
          data={feedbackData}
          onApprove={handleApprove}
          onDelete={handleDelete}
        />
      )}

    </div>
  );
};

const FeedbackTable = ({ data, onApprove, onDelete }) => {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Contact Number</th>
          <th scope="col">Company</th>
          <th scope="col">Rating</th>
          <th scope="col">Feedback</th>
          <th scope="col">Email</th>
          <th scope="col">Approved</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((feedback) => (
          <tr key={feedback._id}>
            <td>{feedback?.name}</td>
            <td><a href={`tel:91${feedback?.contactNumber}`}>{'91' + feedback?.contactNumber}</a></td>
            <td>{feedback?.company}</td>
            <td>{feedback?.rating}</td>
            <td style={{ maxWidth: '200px', whiteSpace: 'normal' }}>{feedback?.feedback}</td>
            <td><a href={`mailto:${feedback?.email}`}>{feedback?.email}</a></td>
            <td>{feedback.approved ? 'Yes' : 'No'}</td>
            <td>
              <button
                onClick={() => onApprove(feedback._id)}
                disabled={feedback.approved}
                className="btn btn-sm btn-primary me-2"
              >
                Approve
              </button>
              <button
                onClick={() => onDelete(feedback._id)}
                className="btn btn-sm btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Feedbacks;