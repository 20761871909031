import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import GallaryImages from "../../../util/gallaryImages.json";
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import { Spinner } from "react-bootstrap";

const HappyCustomer = () => {
  const images = GallaryImages;
  var settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    arrows: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };


  const [uploadedImages, setUploadedImages] = useState([]);
  const [isFetching, setFetching] = useState(false)

  const fetchUploadedImages = async () => {
    setFetching(true)
    try {
      const storage = getStorage();
      const bannerRef = ref(storage, 'gallery');
      const images = await listAll(bannerRef);
      const uploadedImagesData = [];

      // Fetch metadata for each image
      const metadataPromises = images.items.map(async (itemRef) => {
        const url = await getDownloadURL(ref(itemRef));
        const metadata = await getMetadata(ref(itemRef));
        const imageData = {
          url: url,
          metadata: metadata,
        };
        uploadedImagesData.push(imageData);
      });

      // Wait for all metadata to be fetched
      await Promise.all(metadataPromises);
      // Set state with the uploaded images data
      setUploadedImages(uploadedImagesData);
      setFetching(false)
    } catch (error) {
      console.error('Error fetching uploaded images:', error);
      setFetching(false)
    }
  };
  useEffect(() => {
    fetchUploadedImages();
  }, []); // Empty dependency array to run only once on mount


  return (
    <section style={{ marginBottom: "70px" }}>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b8d53e1"
            data-id="b8d53e1"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-879d239 elementor-widget elementor-widget-drivco_testimonial"
                  data-id="879d239"
                  data-element_type="widget"
                  data-widget_type="drivco_testimonial.default"
                >
                  <div className="elementor-widget-container">
                    <div className="home2-testimonial-section ">
                      <div className="container">
                        <div className="row mb-50">
                          <div className="col-lg-12">
                            <div className="section-title-2 text-center">
                              <h2 className="styletwobrandt">
                                Happy Customers
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div >
                          {isFetching && <div style={{margin:'auto', display:'table'}}><Spinner/></div>}
                          <Slider {...settings} className="slider-arrow">
                            {uploadedImages.map((item) => (
                              <div
                                className="swiper-slide"
                                style={{ minWidth: "350px" }}
                              >
                                <div
                                  className="gallery-item"
                                  style={{ padding: "10px" }}
                                >
                                  <img
                                    decoding="async"
                                    className="img-fluid frame"
                                    src={item.url}
                                    alt="gallery" 
                                    style={{background:'black'}}
                                    width={350}
                                    height={300}
                                  />
                                  <a
                                    href="https://craftsnippets.com/articles_images/placeholder/placeholder.jpg"
                                    data-fancybox="gallery"
                                    className="gallary-item-overlay"
                                  />
                                </div>
                              </div>
                            ))}
                            <div
                              className="swiper-slide"
                              style={{ minWidth: "350px" }}
                            ></div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HappyCustomer;
