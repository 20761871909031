import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthProvider/AuthProvider'
import SocialLogin from '../SocialLogin/SocialLogin';
import imageUndraw from '../../assets/images/login-undraw.svg'
import { restApiUri } from '../../constants';

const Login = () => {

  const { register, handleSubmit, formState: { errors } } = useForm()
  const { signIn } = useContext(AuthContext)
  const [loginError, setLoginError] = useState('')

  const location = useLocation()
  const navigate = useNavigate()
  const from = location.state?.from?.pathname || '/'

  const handleLogin = data => {

    setLoginError('');
    signIn(data.email, data.password)
      .then(res => {
        const user = res.user;
        const currentUser = {
          uid: user.uid
        }
        //// GET JWT TOKEN
        fetch(`${restApiUri}/jwt`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(currentUser)
        })
          .then(res => res.json())
          .then(data => {
            localStorage.setItem('access-token', data.token);
            navigate(from, { replace: true });
            toast.success(`Welcome ${user.displayName}`)
            navigate('/dashboard')
          });
      })
      .catch(error => {
       
        if(error.message.includes('auth/invalid-login-credentials')){
          toast.error('Invalid Email or Password');
          setLoginError('Invalid Email or Password');
        }else {
          toast.error(error.message);
          setLoginError(error.message);
        }
      
      });
  }

  return (
    <div className='container md:mx-auto my-10'>
      <div className="login-form-flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg  dark:bg-gray-800 lg:max-w-4xl">
        <div className="login-image bg-cover lg:block lg:w-1/2"
          style={{
            backgroundImage: `url(${imageUndraw})`,
            backgroundRepeat: 'round',
            objectFit: 'contain',
          }}></div>

        <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
          <h1 className="text-3xl font-semibold text-gray-700 dark:text-black">Login</h1>

          {loginError &&
            <div className="alert alert-error  rounded py-2 mt-4">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{loginError}</span>
              </div>
            </div>}

          <form className="mt-6" onSubmit={handleSubmit(handleLogin)}>
            <div>
              <label for="email" className="block text-sm text-gray-800 dark:text-black-200">Email</label>
              <input type="text" {...register('email', {
                required: "Email is required"
              })} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-black-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
              {errors.email && <p className='text-red-500'>{errors.email.message}</p>}
            </div>

            <div className="mt-4">
              <div className="flex items-center justify-between">
                <label for="password" className="block text-sm text-gray-800 dark:text-black-200">Password</label>
                <Link className="text-xs text-gray-600 dark:text-black-400 hover:underline">Forget Password?</Link>
              </div>

              <input type="password" {...register("password", {
                required: "Password is required",
              })} className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-black-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
              {errors.password && <p className='text-red-500'>{errors.password.message}</p>}
            </div>

            <div className="mt-6">
              <button
                style={{ display: 'table', margin: 'auto', width: '100%' }}
                className="primary-btn3 woocommerce-button button woocommerce-form-login__submit">
                Login
              </button>
            </div>
          </form>

          <SocialLogin />

          <p className="mt-8 text-xs font-light text-center text-black-400" style={{fontSize: "14px"}}> Don't have an account? {' '}
            <Link to="/register"
              style={{ fontSize: '16px', padding: '5px 7px', textDecoration: "underline", color: "#0693e3" }}
              className="text-black-700 dark:text-black-200 hover:underline">
              Register</Link>
          </p>

        </div>
      </div>
    </div>
  );
};

export default Login;