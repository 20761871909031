import React from 'react';

const CarInventory = () => {
  return (
    <div className='container'>
      <h2 className='text-3xl font-bold'>Car Inventory</h2>
    </div>
  );
};

export default CarInventory;