// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBf2P5fQlk5UOrxxqwcn_z_aKgc8R2xuyg",
  authDomain: "arjan-motors.firebaseapp.com",
  projectId: "arjan-motors",
  storageBucket: "arjan-motors.appspot.com",
  messagingSenderId: "333127875811",
  appId: "1:333127875811:web:8a8d80485c859dc4d719d6",
  measurementId: "G-VXT04VYH70"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { app, storage };
