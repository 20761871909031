import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { AuthContext } from "../../../contexts/AuthProvider/AuthProvider";
import LoadingSpinner from "../../utilities/LoadingSpinner/LoadingSpinner";
import { restApiUri } from "../../../constants";
import { Link } from "react-router-dom";

const SavedVehicle = (props) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [savedVehicles, setSavedVehicles] = useState([]);

  useEffect(() => {
    const vehicles = JSON.parse(localStorage.getItem("wishlist")) || [];
    setSavedVehicles(vehicles);
  }, []);

  console.log(savedVehicles, "local storage vehicle");
  const handleRemoveProduct = (productId) => {
    const status = window.confirm("Selected saved vehicle will be deleted!");
    if (status) {
      console.log(productId, "hello product id");
      const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      let updatedWishlist = [...savedWishlist];

      const vehicleIndex = updatedWishlist.findIndex(
        (v) => v._id === productId
      );
      console.log(vehicleIndex, "hello index", updatedWishlist, savedWishlist);
      if (vehicleIndex !== -1) {
        console.log("splice", productId);
        updatedWishlist.splice(vehicleIndex, 1);
      }
      console.log(updatedWishlist, "upadted wishlst");
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      setSavedVehicles(updatedWishlist);
    }
  };

  const {
    data: products = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["products", user?.uid],
    queryFn: async () => {
      const res = await fetch(`${restApiUri}/my-products?uid=${user.uid}`, {
        headers: {
          authorization: `bearer ${localStorage.getItem("access-token")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  return (
    <>
      <div className="overflow-x-auto w-full">
        <h3 className="text-lg font-medium leading-6 text-gray-900 pb-5">
          {" "}
          <i class="ph-bold ph-car"></i>&nbsp;&nbsp;Saved Vehicles
        </h3>
        {loading && <LoadingSpinner></LoadingSpinner>}
        {isLoading && <LoadingSpinner></LoadingSpinner>}
        <table className="table w-full">
          <thead>
            <tr>
              <th>SL</th>
              <th>Name</th>
              <th>Body</th>
              <th>Fuel</th>
              <th>Price</th>
              <th>Exterior Color</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              Array.isArray(savedVehicles) &&
              savedVehicles?.map((product, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar">
                        <div className="rounded w-16 h-12">
                          <img
                            src={product.images[0]}
                            alt={product?.make + " " + product?.model}
                          />
                        </div>
                      </div>
                      <div>
                        <Link to={`/car/${product?._id}`}>
                          <div className="font-bold">
                            {product?.carBrand + " " + product?.carModel}
                          </div>
                        </Link>
                        <div className="text-sm opacity-50">
                          {product?.year}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{product?.bodyType}</td>
                  <td>{product?.fuelType}</td>
                  <td>₹{product?.price || 0}</td>
                  <td>{product?.exteriorColor}</td>
                  <th>
                    <label
                      htmlFor="confirmAlert"
                      className="btn btn-sm btn-error rounded text-white cursor-pointer"
                      onClick={() => handleRemoveProduct(product?._id)}
                    >
                      <FaTrashAlt style={{}}></FaTrashAlt>
                    </label>
                  </th>
                </tr>
              ))}
            {savedVehicles.length <= 0 && (
              <tr>
                <td colSpan="12">
                  <h2 className="text-center font-bold">No Vehicles Found</h2>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SavedVehicle;
