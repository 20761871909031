import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import ProgressiveImage from "react-progressive-image-loading";

import "./Banner.css";

import { Link } from "react-router-dom";
import Filters from "../../Products/Filters";
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import { Spinner } from "react-bootstrap";
import placeholderImage from './bg-banner-placeholder.jpeg'


const Banner = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isFetching, setFetching] = useState(true)

  const fetchUploadedImages = async () => {
    setFetching(true)
    try {
      const storage = getStorage();
      const bannerRef = ref(storage, 'banners');
      const images = await listAll(bannerRef);
      const uploadedImagesData = [];

      // Fetch metadata for each image
      const metadataPromises = images.items.map(async (itemRef) => {
        const url = await getDownloadURL(ref(itemRef));
        const metadata = await getMetadata(ref(itemRef));
        const imageData = {
          url: url,
          metadata: metadata,
        };
        uploadedImagesData.push(imageData);
      });

      // Wait for all metadata to be fetched
      await Promise.all(metadataPromises);
      // Set state with the uploaded images data
      setUploadedImages(uploadedImagesData);
      // setFetching(false)
    } catch (error) {
      console.error('Error fetching uploaded images:', error);
      setFetching(false)
    }
  };
  useEffect(() => {
    fetchUploadedImages();
  }, []); // Empty dependency array to run only once on mount

  return (
    <>
      <section className="banner-area">
        <div className="">
          <div
            className=""
            id="banner-image-none"
            style={{
              display: isFetching ? 'block' : 'none',
              backgroundColor: '#1B1C21',
              height: '60vh',
              width: '100%'
            }}
          >
            <img src={placeholderImage}
              style={{
                height: '60vh',
                width: '100%',
                opacity: 0.6,
                objectFit: 'contain'
              }}

            />
          </div>
          <Swiper
            style={{
              display: isFetching ? 'none' : 'block',
              backgroundColor: 'white'
            }}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, EffectFade]}
            effect="fade"
            className="mySwiper"
          >
            {uploadedImages.map((image, index) => {
              let savedDetails = image.metadata.name.split('#napps#')

              if (savedDetails.length > 1) {
                savedDetails = savedDetails[1]
                savedDetails = JSON.parse(savedDetails)
              }
              console.log(savedDetails)
              return (<SwiperSlide>
                <div
                  className="single-banner-slide"
                >
                  <img src={image.url}
                    class="single-banner-image"
                    height='100px'
                    wudth='100px'
                    onLoad={() => { index === uploadedImages.length - 1 && setFetching(false) }}
                  />
                  <div className='mobile-banner-text' style={{ position: 'absolute', zIndex: 999,  }}>
                    <h2 className="heading-caption">{savedDetails.caption}</h2>
                    {savedDetails.buttonLabel && <Link to={savedDetails.actionUrl}>{savedDetails.buttonLabel}</Link>}
                  </div>
                </div>
              </SwiperSlide>)
            })}

          </Swiper>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
        <div className="container home-inventory">
          <Filters isHome />
        </div>
      </section>
    </>
  );
};

export default Banner;
