import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useAdmin from '../../hooks/useAdmin/useAdmin';
import { AuthContext } from '../../contexts/AuthProvider/AuthProvider';
import VerifyProducts from '../Products/VerifyProducts/VerifyProducts';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, logOut } = useContext(AuthContext);
  const [isAdmin, isAdminLoading] = useAdmin(user?.uid);

  const navigateTo = (route) => {
    navigate(route);
  };

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900 pb-5"><i class="ph-bold ph-gauge"></i>&nbsp;Dashboard</h3>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
        <div className='h-36 flex items-center'>
          <button
            onClick={() => navigateTo('/dashboard/add-product')}
            className='w-full h-full px-8 border border-transparent text-black text-2xl font-medium bg-white bg-opacity-70 hover:bg-opacity-80 transition duration-300'
          >
            <i class="ph-bold ph-plus-circle"></i>&nbsp;&nbsp;
            Post Your Vehicle
          </button>
        </div>
        <div className='h-36 flex items-center'>
          <button
            onClick={() => navigateTo('/dashboard/products')}
            className='w-full h-full px-8 border border-transparent text-black text-2xl font-medium bg-white bg-opacity-70 hover:bg-opacity-80 transition duration-300'
          >
            <i class="ph-bold ph-files"></i>&nbsp;&nbsp;
            Posted Vehicles
          </button>
        </div>
        <div className='h-36 flex items-center'>
          <button
            onClick={() => navigateTo('/dashboard/saved-vehicles')}
            className='w-full h-full px-8 border border-transparent text-black text-2xl font-medium bg-white bg-opacity-70 hover:bg-opacity-80 transition duration-300'
          >
            <i class="ph-bold ph-heart"></i>&nbsp;&nbsp;
            Saved Vehicles
          </button>
        </div>
      </div>
      {isAdmin && (
        <>
          <VerifyProducts />
        </>)}

    </>
  );
};

export default Dashboard;
