import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { restApiUri } from "../../../constants";
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import { Spinner } from "react-bootstrap";

var settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  arrows: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      },
    },
  ],
};
const CustomerReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const [uploadedImages, setUploadedImages] = useState([]);
  const [isFetching, setFetching] = useState(false)

  const fetchUploadedImages = async () => {
    setFetching(true)
    try {
      const storage = getStorage();
      const bannerRef = ref(storage, 'gallery');
      const images = await listAll(bannerRef);
      const uploadedImagesData = [];

      // Fetch metadata for each image
      const metadataPromises = images.items.map(async (itemRef) => {
        const url = await getDownloadURL(ref(itemRef));
        const metadata = await getMetadata(ref(itemRef));
        const imageData = {
          url: url,
          metadata: metadata,
        };
        uploadedImagesData.push(imageData);
      });

      // Wait for all metadata to be fetched
      await Promise.all(metadataPromises);
      // Set state with the uploaded images data
      setUploadedImages(uploadedImagesData);
      setFetching(false)
    } catch (error) {
      console.error('Error fetching uploaded images:', error);
      setFetching(false)
    }
  };
  useEffect(() => {
    fetchUploadedImages();
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true
      try {
        const response = await axios.get(`${restApiUri}/happy-customers`); // Replace with your actual endpoint
        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching (or on error)
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once on mount

  return (
    <section style={{ marginBottom: "70px" }}>
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b8d53e1"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-879d239 elementor-widget elementor-widget-drivco_testimonial"
                >
                  <div className="elementor-widget-container">
                    <div className="home2-testimonial-section ">
                      <div className="container">
                        <div className="row mb-50">
                          <div className="col-lg-12">
                            <div className="section-title-2 text-center">
                              <h2 className="styletwobrandt">Our Customer Reviews</h2>
                            </div>
                          </div>
                        </div>
                        {isLoading ? (
                          <p>Loading customer reviews...</p>
                        ) : (
                          <Slider {...settings} className="slider-arrow">
                            {reviews.map((review) => (
                              <div key={review._id} className="swiper-slide" style={{ minWidth: "350px" }}>
                                <div className="feedback-card">
                                  <div className="feedback-top">
                                    <div className="stat-area">
                                      <ul className="trustpilot">
                                        {new Array(Number(review.rating)).fill(0).map(() => (
                                          <li>
                                            <i className="bi bi-star-fill" />
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="services">
                                      <span>{review?.company}</span>
                                    </div>
                                  </div>
                                  <p>
                                    {review?.feedback}
                                  </p>
                                  <div className="author-name">
                                    <h6>{review.name}</h6>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
