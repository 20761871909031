import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa';
import { restApiUri } from '../../constants';
import toast from 'react-hot-toast';

const BrandsComponent = () => {

    const [data, setData] = useState([]);
    const [newBrand, setNewBrand] = useState('');
    const [newModel, setNewModel] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [collapse, setCollapse] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch brands models data
        fetch(`${restApiUri}/brands-model`)
          .then(response => response.json())
          .then(data => {
            setData(data);
            setIsLoading(false);
          })
          .catch(error => console.error('Error fetching brands models:', error));
      }, []);

    const toggleCollapse = (index) => {
        setCollapse([...collapse.slice(0, index), !collapse[index], ...collapse.slice(index + 1)]);
    };

    const handleAddBrand = () => {
        setData([...data, { brand: newBrand, models: [] }]);
        setNewBrand('');
    };

    const handleAddModel = (brandIndex) => {
        const newData = [...data];
        newData[brandIndex].models.push(newModel);
        setData(newData);
        setNewModel('');
    };

    const handleDeleteBrand = (index) => {
        if (window.confirm('Are you sure you want to delete this brand?')) {
            const newData = [...data];
            newData.splice(index, 1);
            setData(newData);
        }
    };

    const handleDeleteModel = (brandIndex, modelIndex) => {
        if (window.confirm('Are you sure you want to delete this model?')) {
            const newData = [...data];
            newData[brandIndex].models.splice(modelIndex, 1);
            setData(newData);
        }
    };

    const updateBrandsModels = async (updatedData) => {
        setIsLoading(true);
        try {
          const response = await fetch(`${restApiUri}/brands-model`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              authorization: `bearer ${localStorage.getItem("access-token")}`,
            },
            body: JSON.stringify(updatedData),
          });
          
          if (!response.ok) {
            throw new Error('Failed to update brands models data');
          }
    
          const newData = await response.json();
          console.log(newData)
          setIsLoading(false);
          toast.success(newData.message)
        } catch (error) {
            setIsLoading(false);
          console.error('Error updating brands models:', error);
        }
      };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Brands and Models</h2>
            <ul>
                {data.map((brand, index) => (
                    <li key={index} className="mb-4">
                        <div className="flex items-center justify-between">
                            <strong>{brand.brand}</strong>
                            <div>
                                <button className="ml-4 bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleDeleteBrand(index)}><FaTrash /></button>
                                <button style={{background:'black'}} className="ml-4 bg-blue-500 text-white px-2 py-1 rounded" onClick={() => toggleCollapse(index)}>{collapse[index]?<FaPlus />:<FaMinus />}</button>
                            </div>
                        </div>
                        {!collapse[index] && (
                            <ul className="pl-8">
                                {brand.models.map((model, modelIndex) => (
                                    <li key={modelIndex} className="flex items-center justify-between">
                                        <span>{model}</span>
                                        <button className="bg-red-500 text-white px-2 py-1 rounded" style={{margin:'5px 0px'}} onClick={() => handleDeleteModel(index, modelIndex)}><FaTrash /></button>
                                    </li>
                                ))}
                            </ul>
                        )}
                        {!collapse[index] && (
                            <div className="mt-2">
                                <input type="text" style={{background:'white',marginLeft:'30px'}} value={newModel} onChange={(e) => setNewModel(e.target.value)} className="border border-gray-300 rounded px-2 py-1 mr-2" />
                                <button style={{background:'#D7B65D'}} className="bg-green-500 text-white px-2 py-1 rounded" onClick={() => handleAddModel(index)}>Add Model</button>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
            <div className="mt-4">
                <input type="text" value={newBrand} style={{ background: 'white' }} onChange={(e) => setNewBrand(e.target.value)} className="border border-gray-300 rounded px-2 py-1 mr-2" />
                <button style={{background:'#D7B65D'}} className="bg-green-500 text-white px-2 py-1 rounded" onClick={handleAddBrand}>Add Brand</button>
            </div>
            <br/>
            <Button disabled={isLoading} style={{background:'black',display:'table',margin:'auto'}} onClick={()=>{
                const confirm = window.confirm('Do you want to save the changes ? Once saved can not revert.')
                if(confirm){
                    updateBrandsModels(data)
                }

            }}>{isLoading?'Saving...':'Save All'}</Button>
        </div>
      
    );
};

export default BrandsComponent;
