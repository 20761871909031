import { useQuery } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FaPenAlt, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { AuthContext } from "../../../contexts/AuthProvider/AuthProvider";
import ConfrimAlert from "../../utilities/ConfirmAlert/ConfrimAlert";
import LoadingSpinner from "../../utilities/LoadingSpinner/LoadingSpinner";
import { restApiUri } from "../../../constants";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Spinner } from 'react-bootstrap';
import AddProduct from "../AddProduct/AddProduct";

const MyProducts = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [editProductData, setEditProductData] = useState("");
  const { user } = useContext(AuthContext);
  const [deleteProduct, setDeleteProduct] = useState("");
  const [ads, setAds] = useState("");
  const [removeAds, setRemove] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEditProduct = (data) => {
    setEditProduct(true);
    setEditProductData(data);
    console.log("hello modal", data);
  };

  const handleCloseEditProductModal = () => {
    setEditProduct(false);
  }
  console.log(editProductData._id, "edit Prodcts Data");

  // const openOfferModal = () => {
  //   setIsOfferOpen(true);
  // };

  const handleCloseEdit = () => {
    setEditProduct(false);
  };

  const {
    data: products = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["products", user?.uid],
    queryFn: async () => {
      const res = await fetch(`${restApiUri}/my-products?uid=${user.uid}`, {
        headers: {
          authorization: `bearer ${localStorage.getItem("access-token")}`,
        },
      });
      const data = await res.json();
      return data;
    },
  });

  const handleAds = (product) => {
    setLoading(true);
    fetch(`${restApiUri}/product/get-ads/${product._id}`, {
      method: "PUT",
      headers: {
        authorization: `bearer ${localStorage.getItem("access-token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`${product.name} ads published`);
          setLoading(false);
          refetch();
        }
      })
      .catch((err) => {
        console.error(err.message);
        toast.error("Something happened wrong!");
      });
  };

  const handleRemoveAds = (product) => {
    setLoading(true);
    fetch(`${restApiUri}/product/remove-ads/${removeAds._id}`, {
      method: "PUT",
      headers: {
        authorization: `bearer ${localStorage.getItem("access-token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`${product.name} ads removed`);
          setLoading(false);
          refetch();
        }
      })
      .catch((err) => {
        console.error(err.message);
        toast.error("Something happened wrong!");
      });
  };

  const handleproductDelete = (product) => {
    const status = window.confirm(`${product.carBrand + ' ' + product.carModel + ' ' + product.year} will be deleted!`)
    if (status) {
      setLoading(true);
      fetch(`${restApiUri}/product/${product._id}`, {
        method: "DELETE",
        headers: {
          authorization: `bearer ${localStorage.getItem("access-token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.deletedCount > 0) {
            refetch();
            setLoading(false);
            toast.success(
              `${product.carBrand} ${product.carModel} deleted successfully!`
            );
          }
        })
        .catch((err) => {
          console.error(err.message);
          toast.error("Something happened wrong!");
        });
    }
  };

  const hanleSoldStatus = (product, status) => {
    setLoading(true);
    fetch(`${restApiUri}/product/change-car-status/${product._id}/${status}`, {
      method: "PUT",
      headers: {
        authorization: `bearer ${localStorage.getItem("access-token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`${product?.carBrand + " " + product?.carModel} is ${!status ? 'SOLD' : 'AVAILABLE'}`);
          setLoading(false);
          refetch();
        }
      })
      .catch((err) => {
        console.error(err.message);
        toast.error("Something happened wrong!");
      });
  };

  return (
    <>
      <div className="overflow-x-auto w-full">
        <h3 className="text-lg font-medium leading-6 text-gray-900 pb-5">
          {" "}
          <i class="ph-bold ph-car"></i>&nbsp;&nbsp;My Vehicles
        </h3>
        {loading && <LoadingSpinner></LoadingSpinner>}
        {isLoading && <LoadingSpinner></LoadingSpinner>}
        <table className="table w-full">
          <thead>
            <tr>
              <th>SL</th>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Body</th>
              <th>Fuel</th>
              <th>Price</th>
              <th>Exterior Color</th>
              <th>Added Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              Array.isArray(products) && products?.map((product, i) => (
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar">
                        <div className="rounded w-16 h-12">
                          <img
                            src={product.images[0]}
                            alt={product?.make + " " + product?.model}
                          />
                        </div>
                      </div>
                      <div>
                        <Link to={`/car/${product?._id}`}>
                          <div className="font-bold">
                            {product?.carBrand + " " + product?.carModel}
                          </div>
                        </Link>
                        <div className="text-sm opacity-50">
                          {product?.year}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{product?.contactNumber && <a href={`tel:${'91' + product?.contactNumber}`}>{'91' + product?.contactNumber}</a>}</td>
                  <td>{product?.bodyType}</td>
                  <td>{product?.fuelType}</td>
                  <td>₹{product?.price || 0}</td>
                  <td>{product?.exteriorColor}</td>
                  <td>{product?.addDate}</td>
                  <td>
                    {product?.sellStatus === true ?
                      <button className="btn btn-xs rounded text-white" onClick={() => hanleSoldStatus(product, false)}>Change to Sold</button>
                      :
                      <button className="btn btn-xs rounded text-white" onClick={() => hanleSoldStatus(product, true)}>Change to Available</button>
                    }
                  </td>
                  {/* <td>
                    {
                      product?.sellStatus ?
                        product?.adsStatus === 'yes' ?
                          <label htmlFor="ConfirmRemoveAds" onClick={() => setRemove(product)} className="btn btn-sm btn-warning rounded text-white">Remove Ads</label>
                          :
                          <label htmlFor="ConfirmAds" onClick={() => setAds(product)} className="btn btn-sm btn-primary rounded text-white">Get Ads</label>
                        : null
                    }

                  </td> */}
                  <th>
                    <label
                      htmlFor="confirmAlert"

                      className="btn btn-sm  rounded text-white cursor-pointer"
                      style={{ marginRight: "10px", background: "#D7B65D" }}
                      onClick={() => handleEditProduct(product)}
                    >
                      <FaPencilAlt ></FaPencilAlt>
                    </label>
                    <label
                      htmlFor="confirmAlert"
                      className="btn btn-sm btn-error rounded text-white cursor-pointer"
                      onClick={() => handleproductDelete(product)}
                    >
                      <FaTrashAlt style={{}}></FaTrashAlt>
                    </label>
                  </th>
                </tr>
              ))}
            {products.length <= 0 && (
              <tr>
                <td colSpan="12">
                  <h2 className="text-center font-bold">No Vehicles Found</h2>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {ads && (
        <ConfrimAlert
          htmlFor="ConfirmAds"
          title={`Make sure, you want to publish Ads?`}
          message={`If you make it, you will be see the product ads on store`}
          successAction={handleAds}
          successButtonName="Confirm"
          modalData={ads}
        ></ConfrimAlert>
      )}

      {removeAds && (
        <ConfrimAlert
          htmlFor="ConfirmRemoveAds"
          title={`Make sure, you want to removed Ads?`}
          message={`If you make it, you can't see the product ads on store`}
          successAction={handleRemoveAds}
          successButtonName="Confirm"
          modalData={ads}
        ></ConfrimAlert>
      )}

      {/* {deleteProduct && (
        <ConfrimAlert
          htmlFor="confirmAlert"
          title={`Are you sure want to delete?`}
          message={`If you delete ${deleteProduct.name}, it cannot be undone.`}
          successAction={handleproductDelete}
          successButtonName="Delete"
          modalData={deleteProduct}
        ></ConfrimAlert>
      )} */}
      {editProduct && (
        <div className="modal-single-car-overlay" >
          <div className="modal-single-car modal-single-car-height" >
            <div className="modal-header">
              <h2>
                <i
                  class="bi bi-calendar-date"
                  style={{ marginRight: "10px" }}
                ></i>
                Edit Product
              </h2>
              <i
                class="bi bi-x-circle"
                style={{ fontSize: "25px", color: "#fff" }}
                onClick={handleCloseEdit}
              ></i>
            </div>
            <AddProduct editProduct={editProduct} editProductData={editProductData} handleCloseEditProductModal={handleCloseEditProductModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default MyProducts;
