import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { format, setYear } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider/AuthProvider";
import { storage } from "../../../firebase/firebase.config";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { restApiUri } from "../../../constants";
import { Stepper } from "react-form-stepper";
import { Form, Spinner } from "react-bootstrap";
import useQueryParams from "../../../util/utils";
import { FilePondComponent } from "../../../others/ReactDropZone/ReactDropZone";
import useAdmin from "../../../hooks/useAdmin/useAdmin";



const AddProduct = (props) => {
  const myParams = useQueryParams();
  const [brandsModals, setBrandsModals] = useState([]);
  const [carBrand, setCarBrand] = useState(
    props?.editProductData?.carBrand || ""
  );
  const [selectedCarModels, setSelectedCarModels] = useState();
  const { user } = useContext(AuthContext);
  const [isAdmin, isAdminLoading] = useAdmin(user?.uid);
  const imageHostKey = process.env.REACT_APP_imgbb_key;
  const navigate = useNavigate();
  const date = format(new Date(), "P");
  const [disabled, setDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [vehicleCondition, setVehicleCondition] = useState(false);
  const [vehicleInformation, setVehicleInformation] = useState(true);
  const [brandName, SetBrandName] = useState("");
  const [carModel, SetCarModel] = useState(
    props?.editProductData?.carModel || ""
  );
  const [year, SetYear] = useState("");
  const [price, SetPrice] = useState(props?.editProductData?.price || "");
  const [transmission, SetTransmission] = useState(
    props?.editProductData?.transmission || ""
  );
  const [kmDriven, SetKmDriven] = useState(
    props?.editProductData?.kmDriven || ""
  );
  const [engine, SetEngine] = useState(props?.editProductData?.engine || "");
  const [wheels, SetWheels] = useState(props?.editProductData?.wheels || "");
  const [seats, SetSeats] = useState(props?.editProductData?.seats || "");
  const [registerState, SetRegistrationState] = useState(
    props?.editProductData?.registerState || ""
  );
  const [exteriorColor, SetExteriorColor] = useState(
    props?.editProductData?.exteriorColor || ""
  );
  const [interiorColor, SetInteriorColor] = useState(
    props?.editProductData?.interiorColor || ""
  );
  const [ownerSerial, SetOwnerSerial] = useState(
    props?.editProductData?.ownerSerial || ""
  );
  const [fuelType, SetFuelType] = useState(
    props?.editProductData?.fuelType || ""
  );
  const [bodyType, SetBodyType] = useState(
    props?.editProductData?.bodyType || ""
  );
  const [carFeatures, SetCarFeatures] = useState(
    props?.editProductData?.carFeatures || ""
  );
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [discription, SetDiscription] = useState(
    props?.editProductData?.discription || ""
  );
  const [exteriorCondition, setExteriorCondition] = useState(
    props?.editProductData?.exteriorCondition || null
  );
  const [interiorCondition, setInteriorCondition] = useState(
    props?.editProductData?.interiorCondition || null
  );
  const [accident, setAccident] = useState(
    props?.editProductData?.accident || null
  );

  const [contactNumber, setContactNumber] = useState(
    props?.editProductData?.contactNumber || null
  );
  const [isLoading, setIsLoading] = useState(true);

  console.log(props, "props from edit");

  const handleVehicleAccident = (vehicle) => {
    setAccident(vehicle === accident ? null : vehicle);
  };

  const handleExteriorCondition = (condition) => {
    setExteriorCondition(condition);
  };

  const handleInteriorCondition = (insideCondition) => {
    setInteriorCondition(
      insideCondition === interiorCondition ? null : insideCondition
    );
  };

  console.log(price, "price bugs");

  // Get Category
  const { data: categories = [] } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const res = await fetch(`${restApiUri}/categories`);
      const data = await res.json();
      return data;
    },
  });

  const handlePrevious = () => {
    setActiveStep(0);
    setVehicleInformation(true);
    setVehicleCondition(false);
  };

  const handleBrandSelection = (carBrand) => {
    setCarBrand(carBrand);
    const selectedModals = brandsModals.filter(
      (brand) => brand.brand === carBrand
    )[0];
    setSelectedCarModels(selectedModals?.models, "filtered Cars Modals");
  };

  useEffect(() => {
    // Fetch brands models data
    fetch(`${restApiUri}/brands-model`)
      .then((response) => response.json())
      .then((data) => {
        setBrandsModals(data);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching brands models:", error));
  }, []);

  useEffect(() => {
    if (myParams.make) {
      handleBrandSelection(myParams.make);
    }
  }, [myParams.make]);

  useEffect(() => {
    if (props?.editProductData?.carModel && props?.editProductData?.carBrand) {
      handleBrandSelection(props?.editProductData?.carBrand);
      setTimeout(() => {
        SetCarModel(props?.editProductData?.carModel);
        SetTransmission(props?.editProductData?.transmission);
        SetOwnerSerial(props?.editProductData?.ownerSerial);
        SetFuelType(props?.editProductData?.fuelType);
        SetBodyType(props?.editProductData?.bodyType);
        SetDiscription(props?.editProductData?.description);
        SetCarFeatures(props?.editProductData?.carFeatures);
      }, 500);
    }
  }, []);

  // Get Brand
  const { data: brands = [] } = useQuery({
    queryKey: ["brands"],
    queryFn: async () => {
      const res = await fetch(`${restApiUri}/brands`);
      const data = await res.json();
      return data;
    },
  });

  const handleAddVehicle = () => {
    uploadImagesToStorage(images)
      .then((imageUrls) => {
        // Do something with the public URLs, such as storing them in a database
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
      });
  };

  // Add Product
  const handleSubmit = (e) => {
    e.preventDefault();
    if (carBrand.length === 0) {
      return toast.error("Make is Required.");
    }
    if (carModel.length === 0) {
      return toast.error("Model is Required");
    }
    if (year.length === 0) {
      return toast.error("Year is Required");
    }
    if (transmission.length === 0) {
      return toast.error("Transmission is Required");
    }
    if (kmDriven.length === 0) {
      return toast.error("KMS Driven is Required");
    }
    if (registerState.length === 0) {
      return toast.error("Registration State is Required");
    }
    if (exteriorColor.length === 0) {
      return toast.error("Exterior color is Required");
    }
    if (carFeatures.length === 0) {
      return toast.error("Features is Required");
    }
    if (interiorColor.length === 0) {
      return toast.error("Interior is Required");
    }
    if (ownerSerial.length === 0) {
      return toast.error("Owner serial is Required");
    }
    if (fuelType.length === 0) {
      return toast.error("Fuel Type is Required");
    }
    if (bodyType.length === 0) {
      return toast.error("Body Type is Required");
    }
    if (price.length === 0) {
      return toast.error("Price is Required");
    }
    if (seats.length === 0) {
      return toast.error("Seats are Required");
    }
    if (engine.length === 0) {
      return toast.error("Engine capacity is Required");
    }
    if (wheels.length === 0) {
      return toast.error("Wheels are Required");
    }
    if (String(contactNumber).length === 0) {
      return toast.error("Contact Number is Required");
    }
    setActiveStep(1);
    setVehicleInformation(false);
    setVehicleCondition(true);
    if (exteriorCondition === null) {
      return toast.error("Exterior Condition is Required");
    }
    if (interiorCondition === null) {
      return toast.error("Interior Condition is Required");
    }
    if (accident === null) {
      return toast.error("Vehicle accident status is Required.");
    }
    const formData = {
      carBrand: carBrand,
      carModel: carModel,
      year: Number(year),
      wheels: Number(wheels),
      seats: Number(seats),
      price: Number(price),
      transmission: transmission,
      kmDriven: Number(kmDriven),
      registerState: registerState,
      exteriorColor: exteriorColor,
      carFeatures: carFeatures,
      interiorColor: interiorColor,
      ownerSerial: ownerSerial,
      fuelType: fuelType,
      bodyType: bodyType,
      engine: engine,
      description: discription,
      exteriorCondition: exteriorCondition,
      interiorCondition: interiorCondition,
      accident: accident,
      addDate: date,
      timestamp: new Date().getTime() + new Date().getTimezoneOffset() * 60000,
      sellerId: user.uid,
      sellerName: user.displayName,
      sellStatus: true,
      reportStatus: !isAdmin,
      contactNumber
    };
    setDisabled(true);
    uploadImagesToStorage(images)
      .then((imageUrls) => {
        formData["images"] = imageUrls;
        // save doctor information to the database
        fetch(`${restApiUri}/product`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: `bearer ${localStorage.getItem("access-token")}`,
          },
          body: JSON.stringify(formData),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.acknowledged) {
              toast.success(
                `${formData.carBrand} ${formData.carModel}is added successfully`
              );
              navigate("/dashboard/products");
              setDisabled(false);
            }
          });
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
      });
  };

  const handleCheckboxes = (e) => {
    e.preventDefault();
  };

  console.log(props.editProductData?._id, "product Id");
  const handleEditProduct = () => {
    setActiveStep(1);
    setVehicleInformation(false);
    setVehicleCondition(true);
    if (activeStep === 0) {
      return;
    }
    const productId = props.editProductData?._id; // Replace 'your_product_id_here' with the actual product ID
    const updatedProduct = {
      carBrand: carBrand,
      carModel: carModel,
      year: Number(year),
      wheels: Number(wheels),
      seats: Number(seats),
      price: Number(price),
      transmission: transmission,
      kmDriven: Number(kmDriven),
      registerState: registerState,
      exteriorColor: exteriorColor,
      carFeatures: carFeatures,
      interiorColor: interiorColor,
      ownerSerial: ownerSerial,
      fuelType: fuelType,
      bodyType: bodyType,
      engine: engine,
      description: discription,
      exteriorCondition: exteriorCondition,
      interiorCondition: interiorCondition,
      accident: accident,
      addDate: date,
      timestamp: new Date().getTime() + new Date().getTimezoneOffset() * 60000,
      sellerId: user.uid,
      sellerName: user.displayName,
      sellStatus: true,
      reportStatus: true,
    };

    fetch(`${restApiUri}/product/${productId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        authorization: `bearer ${localStorage.getItem("access-token")}`,
      },
      body: JSON.stringify(updatedProduct),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Product updated successfully:", data);
        toast.success(data.message);
        props.handleCloseEditProductModal();
      })
      .catch((error) => {
        console.error("There was a problem updating the product:", error);
        // Handle error
      });
  };
  console.log(props?.editProductData?.description, "hello select");
  return (
    <div className="mt-3 sm:mt-0">
      <h3
        className={`text-lg font-medium leading-6 text-gray-900 ${props.editProduct ? "text-center mt-4" : ""
          }`}
      >
        {" "}
        <i class="ph-bold ph-car-profile"></i>&nbsp;&nbsp;{" "}
        {props.editProduct ? "Edit Product" : "Add Vehicle"}
      </h3>
      <Stepper
        steps={[{ label: "Information" }, { label: "Condition" }]}
        activeColor="#ffd813"
        defaultColor="#eee"
        completeColor="#ffbd13"
        activeTitleColor="#fff"
        completeTitleColor="#eee"
        defaultTitleColor="#bbb"
        circleFontColor="#000"
        completeBarColor="#ffbd13"
        activeStep={activeStep}
      />

      <div
        className=""
        style={{ display: activeStep === 0 ? "block" : "none" }}
      >
        <div
          className="form-shadow mt-4 md:mt-0"
          style={{ padding: "10px", border: "0.5px solid #E5E7EB" }}
        >
          <form className="add-product-form">
            <div className="overflow-hidden sm:rounded-md">
              <div className="bg-white px-1 py-2 sm:p-2">
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block text-sm font-medium mb-2 text-gray-700"
                    >
                      Make*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      required
                      value={carBrand || props?.editProductData?.carBrand}
                      onChange={(e) => handleBrandSelection(e.target.value)}
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option value="">
                        {isLoading ? "Loading..." : "Select Make"}
                      </option>
                      {brandsModals.map((brand, index) => (
                        <option value={brand?.brand}>{brand?.brand}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block text-sm font-medium mb-2 text-gray-700"
                    >
                      Model*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      required
                      value={carModel}
                      onChange={(e) => SetCarModel(e.target.value)}
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option value="">
                        {isLoading ? "Loading..." : "Select Model"}
                      </option>
                      {selectedCarModels &&
                        selectedCarModels?.map((modal, index) => (
                          <option value={modal}>{modal}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Price*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetPrice(e.target.value)}
                      Value={price || props?.editProductData?.price}
                      placeholder="Desired Price"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="sellPrice"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Year*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetYear(e.target.value)}
                      Value={year || props?.editProductData?.year}
                      id="sellPrice"
                      autoComplete="sellPrice"
                      placeholder="2021"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block text-sm font-medium mb-2 text-gray-700"
                    >
                      Transmission*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      required
                      value={transmission}
                      onChange={(e) => SetTransmission(e.target.value)}
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option>Select Transmission</option>
                      <option value="Manual">Manual</option>
                      <option value="Automatic">Automatic</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Engine capacity (CC)*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetEngine(e.target.value)}
                      Value={engine || props?.editProductData?.engine}
                      id="engine"
                      autoComplete="engine"
                      placeholder="2000"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      No. of Wheels*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetWheels(e.target.value)}
                      Value={wheels || props?.editProductData?.wheels}
                      id="wheels"
                      autoComplete="engine"
                      placeholder="5"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      No. of Seats*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetSeats(e.target.value)}
                      Value={seats || props?.editProductData?.seats}
                      id="engine"
                      autoComplete="engine"
                      placeholder="5"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="sellPrice"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      KMS Driven*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="number"
                      onChange={(e) => SetKmDriven(e.target.value)}
                      Value={kmDriven || props?.editProductData?.kmDriven}
                      id="sellPrice"
                      autoComplete="sellPrice"
                      placeholder="12000"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="sellPrice"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Registration State*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      onChange={(e) => SetRegistrationState(e.target.value)}
                      Value={
                        registerState || props?.editProductData?.registerState
                      }
                      id="sellPrice"
                      autoComplete="sellPrice"
                      placeholder="Enter Car Registration State"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      required
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="originalPrice"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Exterior Color*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      onChange={(e) => SetExteriorColor(e.target.value)}
                      Value={
                        exteriorColor || props?.editProductData?.exteriorColor
                      }
                      id="originalPrice"
                      autoComplete="originalPrice"
                      placeholder="Color name"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="originalPrice"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Interior Color*
                    </label>
                    <input
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      onChange={(e) => SetInteriorColor(e.target.value)}
                      Value={
                        interiorColor || props?.editProductData?.interiorColor
                      }
                      id="originalPrice"
                      autoComplete="originalPrice"
                      placeholder="Color name"
                      className="input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                    />
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Owner Serial*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      onChange={(e) => SetOwnerSerial(e.target.value)}
                      value={ownerSerial}
                      required
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option>Select Owner Serial</option>
                      <option value="First Owner">First Owner</option>
                      <option value="Second Owner">Second Owner</option>
                      <option value="Third Owner">Third Owner</option>
                      <option value="Fourth Owner">Fourth Owner</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Fuel Type*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      value={fuelType}
                      onChange={(e) => SetFuelType(e.target.value)}
                      required
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option defaultValue="Select Owner Serial">
                        Select Fuel Type
                      </option>
                      <option defaultValue="Petrol">Petrol</option>
                      <option defaultValue="Diesel">Diesel</option>
                      <option defaultValue="Petrol Hybrid">
                        Petrol Hybrid
                      </option>
                      <option defaultValue="Diesel Hybrid">
                        Diesel Hybrid
                      </option>
                      <option defaultValue="EV">EV</option>
                      <option defaultValue="CNG">CNG</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Body Type*
                    </label>
                    <select
                      id="category"
                      style={{ height: "48px" }}
                      value={bodyType}
                      onChange={(e) => SetBodyType(e.target.value)}
                      required
                      class="bg-white border mt-1 border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:focus:border-blue-500"
                    >
                      <option defaultValue="Select Owner Serial">
                        Select Body Type
                      </option>
                      <option value="Coupe">Coupe</option>
                      <option value="Sedan">Sedan</option>
                      <option value="MUV/SUV">MUV/SUV</option>
                      <option value="Sports Car">Sports Car</option>
                      <option value="Mini Van">Mini Van</option>
                      <option value="Convertible">Convertible</option>
                      <option value="Hatchback">Hatchback</option>
                    </select>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <label
                      for="category"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Contact number*
                    </label>
                    <div class="phone-input-container">
                      <span>+91</span>
                      <input
                        type="number"
                        maxlength="10"
                        pattern="\d{10}"
                        value={contactNumber}
                        placeholder="987654321"
                        onChange={(e) => {
                          if (e.target.value.length > 10) {
                            return;
                          }
                          setContactNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {props.editProduct ? (
                    ""
                  ) : (
                    <div className="col-span-12 md:col-span-12">
                      <label
                        for="productThumbnail"
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Choose Images*
                      </label>
                      {/*
                    <input style={{ height: "48px" }} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" type="file" id="productThumbnail" className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" required /> */}
                      <FilePondComponent
                        setFiles={setImages}
                        setPreviews={setPreviews}
                        previews={previews}
                      // editImagesData={props?.editProductData?.images}
                      />
                    </div>
                  )}
                  <div className="col-span-12">
                    <label
                      for="keyfeatures"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Key Features*
                    </label>
                    <textarea
                      id="keyfeatures"
                      style={{ background: "white" }}
                      onChange={(e) => SetCarFeatures(e.target.value)}
                      value={carFeatures}
                      className="textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      rows="4"
                      placeholder="Alloys, Stereo, Touchscreen, Spacious"
                      required
                    ></textarea>
                  </div>
                  <div className="col-span-12">
                    <label
                      for="description"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Description (Optional)
                    </label>
                    <textarea
                      id="description"
                      style={{ background: "white" }}
                      value={discription}
                      onChange={(e) => SetDiscription(e.target.value)}
                      className="textarea mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#191D25] focus:ring-[#191D25]"
                      rows="6"
                      placeholder="Enter Description"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {activeStep === 1 ? (
        <div className="form-shadow ">
          <div className="container">
            <div className="vehicle-condition-section">
              <h4>What is the Exterior Condition?</h4>
              <div className="vehicle-condition-tabs">
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={exteriorCondition === "extra clean"}
                    onChange={() => handleExteriorCondition("extra clean")}
                  />
                  <Form.Label>Extra Clean</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={exteriorCondition === "clean"}
                    onChange={() => handleExteriorCondition("clean")}
                  />
                  <Form.Label>Clean</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={exteriorCondition === "avenger"}
                    onChange={() => handleExteriorCondition("avenger")}
                  />
                  <Form.Label>Average</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={exteriorCondition === "below Average"}
                    onChange={() => handleExteriorCondition("below Average")}
                  />
                  <Form.Label>Below Average</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={exteriorCondition === "I don't know"}
                    onChange={() => handleExteriorCondition("I don't know")}
                  />
                  <Form.Label>I don't know</Form.Label>
                </Form.Group>
              </div>
              <h4>What is the Interior Condition?</h4>
              <div className="vehicle-condition-tabs">
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={interiorCondition === "extra Clean"}
                    onChange={() => handleInteriorCondition("extra Clean")}
                  />
                  <Form.Label>Extra Clean</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={interiorCondition === "clean"}
                    onChange={() => handleInteriorCondition("clean")}
                  />
                  <Form.Label>Clean</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={interiorCondition === "average"}
                    onChange={() => handleInteriorCondition("average")}
                  />
                  <Form.Label>Average</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={interiorCondition === "below average"}
                    onChange={() => handleInteriorCondition("below average")}
                  />
                  <Form.Label>Below Average</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={interiorCondition === "i don't know"}
                    onChange={() => handleInteriorCondition("i don't know")}
                  />
                  <Form.Label>I don't know</Form.Label>
                </Form.Group>
              </div>
              <h4>Has vehicle been in accident ?</h4>
              <div className="vehicle-condition-tabs">
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={accident === "yes"}
                    onChange={() => handleVehicleAccident("yes")}
                  />
                  <Form.Label>Yes</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={accident === "no"}
                    onChange={() => handleVehicleAccident("no")}
                  />
                  <Form.Label>No</Form.Label>
                </Form.Group>
                <Form.Group className="condition-1">
                  <Form.Check
                    aria-label="option 1"
                    type="radio"
                    checked={accident === "i don't know"}
                    onChange={() => handleVehicleAccident("i don't know")}
                  />
                  <Form.Label>I don't know</Form.Label>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {vehicleInformation ? (
          <div>
            <button
              disabled={disabled}
              onClick={props.editProduct ? handleEditProduct : handleSubmit}
              className="next-btn"
            >
              Next
            </button>
          </div>
        ) : (
          <div>
            <button className="previous-btn" onClick={handlePrevious}>
              Previous
            </button>
          </div>
        )}
        <div>
          {activeStep === 1 && (
            <button
              disabled={disabled}
              className="save-btn"
              onClick={props.editProduct ? handleEditProduct : handleSubmit}
            >
              {disabled ? <Spinner animation="border" /> : "Save"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;

const uploadImagesToStorage = async (images) => {
  const imageUrls = [];

  // Loop through each image in the array
  for (const image of images) {
    // Generate a unique filename for each image (you can customize this logic)
    const filename = `vehicle_${Date.now()}_${image.name}`;

    // Create a reference to the storage location
    const storageRef = ref(storage, `vehicles/${filename}`);

    // Upload the image file to the specified location
    await uploadBytes(storageRef, image);

    // Get the public URL of the uploaded image
    const imageUrl = await getDownloadURL(storageRef);

    // Push the URL to the array
    imageUrls.push(imageUrl);
  }

  return imageUrls;
};
