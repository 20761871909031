import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import ProductsBrandCard from "../../Products/ProductsBrand/ProductsBrandCard/ProductsBrandCard";
import LoadingSpinner from "../../../dashboard/utilities/LoadingSpinner/LoadingSpinner";
import { restApiUri } from "../../../constants";
import { set } from "date-fns";
import { HashLink } from "react-router-hash-link";

const ProductsBrandHome = () => {
  const [tabMake, setTabMake] = useState(true);
  const [tabBodyType, setTabBodyType] = useState(false);
  const [make, setMake] = useState("");
  const navigation = useNavigate();

  const url = `${restApiUri}/brands`;

  const { data: brands = [], isLoading } = useQuery({
    queryKey: ["brands"],
    queryFn: async () => {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    },
  });

  const handleSubmit = (make, bodyType) => {
    // e.preventDefault();
    const queryParams = {};
    if (make.length > 0) {
      queryParams["make"] = make;
    }
    navigation({
      pathname: `/cars`,
      search: new URLSearchParams(queryParams).toString(),
      hash: '#cars-section',  // Include the hash fragment like this
    });
  };

  const handleBodyType = (bodyType) => {
    const queryParams = {};
    if (bodyType.length > 0) {
      queryParams["bodyType"] = bodyType;
    }
    navigation({
      pathname: `/cars`,
      search: new URLSearchParams(queryParams).toString(),
      hash: '#cars-section',  // Include the hash fragment like this
    });
  };

  const handleBodyTypeChange = () => {
    console.log("hello body type");
    setTabBodyType(true);
    setTabMake(false);
  };

  const handleMakeTabChange = () => {
    setTabMake(true);
    setTabBodyType(false);
  };

  return (
    <section style={{ marginBottom: "50px", marginTop: "-30px" }}>
      <div
        className="elementor-container elementor-column-gap-default"
        style={{ marginTop: "30px" }}
      >
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e5eb51c"
            data-id="e5eb51c"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-6a34066 elementor-widget elementor-widget-drivco_brands"
                  data-id="6a34066"
                  data-element_type="widget"
                  data-widget_type="drivco_brands.default"
                >
                  <div className="elementor-widget-container">
                    <div className="dream-car-area">
                      <div className="container">
                        <div className="row mb-30">
                          <div className="col-lg-12">
                            <div className="section-title-2 text-center">
                              <h2 className="styletwobrandt">
                                Search By Make & Type
                              </h2>
                              {/* <p className="styletwobrands">
                                There has 30+ Brand Category Available{" "}
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="filter-area d-flex flex-wrap align-items-center justify-content-between">
                              <ul
                                className="nav nav-pills"
                                id="pills-tab"
                                role="tablist"
                              >
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link active"
                                    id="pills-make-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-make"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-make"
                                    aria-selected="true"
                                    onClick={() => handleMakeTabChange()}
                                  >
                                    Make
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button
                                    className="nav-link"
                                    id="pills-body-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-body"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-body"
                                    aria-selected="false"
                                    onClick={() => handleBodyTypeChange()}
                                  >
                                    Body Type
                                  </button>
                                </li>
                              </ul>
                              <div className="explore-btn d-lg-flex d-none">
                                <Link className="explore-btn2" to="/cars#cars-section">
                                  View All Cars
                                  <i className="bi bi-arrow-right-short" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                          {tabMake && (
                            <div
                              className="tab-pane fade show active"
                              id="pills-make"
                              role="tabpanel"
                              aria-labelledby="pills-make-tab"
                            >
                              <div className="row g-4 justify-content-center">
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Maruti Suzuki#cars-section">
                                    <div
                                      onClick={() =>
                                        handleSubmit("Maruti Suzuki")
                                      }
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Suzuki-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Maruti Suzuki</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Tata#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Tata")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Tata-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Tata</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Hyundai#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Hyundai")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/10/hyundai.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Hyundai</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=MG#cars-section">
                                    <div
                                      onClick={() => handleSubmit("MG")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/mg.jpg"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>MG</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Mercedes#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Mercedes")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Mercedes-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Mercedes</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Audi#cars-section">
                                    <div
                                      className="car-category text-center"
                                      onClick={() => handleSubmit("Audi")}
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Audi-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Audi</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=BMW#cars-section">
                                    <div
                                      onClick={() => handleSubmit("BMW")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/bmw.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>BMW</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Ferrari#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Ferrari")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Ferrari-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Ferrari</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>

                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Jeep#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Jeep")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Jeep-Logo.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Jeep</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>

                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Lamborghini#cars-section">
                                    <div
                                      onClick={() =>
                                        handleSubmit("Lamborghini")
                                      }
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/lamborghini.png"
                                          height={50}
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Lamborghini</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>

                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Porsche#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Porsche")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/porsche.webp"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Porsche</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Jaguar#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Jaguar")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/jaguar.webp"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Jaguar</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Skoda#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Skoda")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/skoda.jpg"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Skoda</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Mini#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Mini")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/mini.png"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Mini</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>

                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Kia#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Kia")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/kia.png"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Kia</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Honda#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Honda")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/honda.png"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Honda</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  <HashLink to="/cars?make=Ford#cars-section">
                                    <div
                                      onClick={() => handleSubmit("Ford")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/ford.png"
                                          height="50px"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Ford</h6>
                                      </div>
                                    </div>
                                  </HashLink>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                  {/* <HashLink to="/cars?make=Toyota#cars-section"> */}
                                    <div
                                      onClick={() => handleSubmit("Toyota")}
                                      className="car-category text-center"
                                    >
                                      <div className="icon">
                                        <img
                                          decoding="async"
                                          src="../content/uploads/2023/09/Toyota.svg"
                                          alt="brand-icon"
                                        />
                                      </div>
                                      <div className="content">
                                        <h6>Toyota</h6>
                                      </div>
                                    </div>
                                  {/* </HashLink> */}
                                </div>
                              </div>
                            </div>
                          )}
                          {tabBodyType && (
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Coupe")}
                                  className="car-category text-center"
                                >
                                  
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/car.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Coupe</h6>
                                    </div>
                                 
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Sedan")}
                                  className="car-category text-center"
                                >
                                 
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/car.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Sedan</h6>
                                    </div>
                                
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Hatchback")}
                                  className="car-category text-center"
                                >
                                 
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/hatchback-car-1.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Hatchback</h6>
                                    </div>
                                
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Sports Car")}
                                  className="car-category text-center"
                                >
                                
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/sport-car.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Sports Cars</h6>
                                    </div>
                           
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("MUV/SUV")}
                                  className="car-category text-center"
                                >
                                 
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/suv.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>MUV/SUV</h6>
                                    </div>
                                
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Mini Van")}
                                  className="car-category text-center"
                                >
                                 
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/mini-van.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Mini Van</h6>
                                    </div>
                                
                                </div>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <div
                                  onClick={() => handleBodyType("Convertible")}
                                  className="car-category text-center"
                                >
                                 
                                    <div className="icon">
                                      <img
                                        decoding="async"
                                        src="../content/uploads/2023/09/convertible-car.png"
                                        alt="logo-icon"
                                      />
                                    </div>
                                    <div className="content">
                                      <h6>Convertible</h6>
                                    </div>
                                 
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            className="tab-pane fade"
                            id="pills-location"
                            role="tabpanel"
                            aria-labelledby="pills-location-tab"
                          >
                            <div className="row g-4 justify-content-center">
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/kualalumpur/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/sydne.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Kualalumpur</h6>
                                    <span>(4)</span>
                                  </div>
                                </a>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/melbourne-city/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/merchester.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Melbourne City</h6>
                                    <span>(6)</span>
                                  </div>
                                </a>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/new-delhi/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/delhi.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>New Delhi</h6>
                                    <span>(3)</span>
                                  </div>
                                </a>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/new-york/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/new-work.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>New York</h6>
                                    <span>(1)</span>
                                  </div>
                                </a>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/panama-city/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/panama.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Panama City</h6>
                                    <span>(5)</span>
                                  </div>
                                </a>
                              </div>
                              <div className="col-xl-2 col-md-3 col-sm-4 col-6">
                                <a
                                  href="../location/sydney-city/index.html"
                                  className="car-category text-center"
                                >
                                  <div className="icon">
                                    <img
                                      decoding="async"
                                      src="../content/uploads/2023/09/abu-dabi.svg"
                                      alt="loction-icon"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6>Sydney City</h6>
                                    <span>(3)</span>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductsBrandHome;
{
  /* 
// <div className="">
    //   <div className='container md:mx-auto'>
    //     <div className="advertise-section py-10">
    //       <div className='flex justify-between w-full'>
    //         <h2 className='text-2xl font-bold'>Cars <span className='text-[#D7B65D]'>Brand</span></h2>
    //         <Link to='/brand' className='text-[black] hover:underline hover:underline-offset-4' style={{fontWeight:'500'}}>View All</Link>
    //       </div>
    //       {isLoading && <LoadingSpinner></LoadingSpinner>}
    //       <div className='py-5'>
    //         <div className="grid grid-cols-12 lg:grid-cols-8 gap-2.5">
    //           {
    //             brands?.slice(0, 8)?.map((brand, i) => <ProductsBrandCard brand={brand} key={i}></ProductsBrandCard>)
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div> */
}
