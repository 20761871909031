import { formatNumber } from '../../../util/utils';
import React, { useEffect, useState, useCallback, useContext } from "react";
import toast from "react-hot-toast";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import BookNowModal from "../../../dashboard/Products/BookNowModal/BookNowModal";
import ConfrimAlert from "../../../dashboard/utilities/ConfirmAlert/ConfrimAlert";
import { Link } from "react-router-dom";
import { restApiUri } from "../../../constants";
import { AuthContext } from "../../../contexts/AuthProvider/AuthProvider";

const ProductCard = ({ product }) => {
  console.log(product, "Available");
  // const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  const [wishlist, setWishlist] = useState([]);
  const [productSeller, setProductSeller] = useState({});
  const [booking, setBooking] = useState("");
  const [report, setReport] = useState("");

  console.log("whistlist", wishlist);
  const { user, logOut } = useContext(AuthContext);
  const userAccessToken = user?.stsTokenManager?.accessToken;
  console.log(userAccessToken, "wishlist user access tken");

  const {
    price,
    bodyType,
    carBrand,
    carModel,
    kmDriven,
    fuelType,
    addDate,
    transmission,
    images,
    year,
    sellerName,
    sellerId,
    sellStatus,
  } = product;

  useEffect(() => {
    fetch(`${restApiUri}/user/₹ {sellerId}`)
      .then((res) => res.json())
      .then((result) => {
        setProductSeller(result);
      })
      .catch((err) => console.error(err));
  }, [sellerId]);

  const handleReport = (product) => {
    fetch(`${restApiUri}/product/report-product/₹ {product._id}`, {
      method: "PUT",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.modifiedCount > 0) {
          toast.success(`₹ {product.name} Reported`);
        }
      })
      .catch((err) => {
        console.error(err.message);
        toast.error("Something happened wrong!");
      });
  };

  console.log("rendered");

  // useEffect(() => {
  //   // Load wishlist from localStorage on component mount
  //   const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  //   setWishlist(savedWishlist);
  // }, []);

  const handleSavedVehicle = useCallback((vehicle) => {
    if (!userAccessToken) {
      toast.error('you have to login first')
      return
    }
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    let updatedWishlist = [...savedWishlist];

    const vehicleIndex = updatedWishlist.findIndex(
      (v) => v._id === vehicle._id
    );
    console.log(vehicleIndex, "hello index", updatedWishlist, savedWishlist);
    if (vehicleIndex !== -1) {
      console.log("splice", vehicle);
      updatedWishlist.splice(vehicleIndex, 1);
    } else {
      console.log("push", vehicle);
      updatedWishlist.push(vehicle);
    }
    setWishlist(updatedWishlist);
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  }, []);

  const isInWishlist = (vehicleId) => {
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist")) || wishlist || [];
    return savedWishlist.some((v) => v._id === vehicleId);
  };

  return (
    <>
      {booking && (
        <BookNowModal
          htmlFor={`confrimBooking₹ {product._id}`}
          successButtonName="Book Know"
          modalData={booking}
        ></BookNowModal>
      )}
      <div className="product-card">
        {!sellStatus && (
          <div
            style={{
              background: "red",
              width: "60px",
              right: 0,
              top: 0,
              color: "white",
              position: "absolute",
              zIndex: 10,
              padding: "0px 10px 0px 10px",
            }}
          >
            Sold
          </div>
        )}
        <div className="product-img">
          <img
            width={900}
            height={485}
            src={images[0]}
            className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
            alt=""
            decoding="async"
            loading="lazy"
            sizes="(max-width: 900px) 100vw, 900px"
            style={{ height: 200, objectFit: "cover", background: "silver" }}
          />
        </div>
        <div className="product-content prod-card">
          <div className="price-location">
            <div className="price" style={{ background: '#F7F7F7', padding: '5px 5px', zoom: 1.2, borderRadius: '5px' }}>
              <strong style={{ color: '#D7B65D', }}>
                ₹ {formatNumber(price)}
              </strong>
            </div>
            <div className="location">
              <a href="../../location/melbourne-city/index.html">
                <i
                  style={{ fontSize: "20px", color: "silver" }}
                  className="ph-bold ph-map-pin"
                ></i>
                Delhi{" "}
              </a>
            </div>

            <i
              className={
                isInWishlist(product._id) ? "fa fa-heart" : "fa fa-heart-o"
              }
              style={{
                padding: "0px",
                color: isInWishlist(product._id) ? "red" : "black",
              }}
              onClick={() => handleSavedVehicle(product)}
            ></i>
          </div>
          <h5 style={{ fontWeight: "bold" }}>{`${carBrand} ${carModel}`}</h5>
          <ul className="features">
            <li>
              <i
                style={{ fontSize: "20px", color: "silver" }}
                className="ph-bold ph-gauge"
              ></i>
              {kmDriven} KMs
            </li>
            <li>
              <i
                style={{ fontSize: "20px", color: "silver" }}
                className="ph-bold ph-gas-can"
              ></i>
              {fuelType}
            </li>
            <li>
              <i
                style={{ fontSize: "20px", color: "silver" }}
                className="ph-bold ph-calendar-blank"
              ></i>
              {year}
            </li>
          </ul>
          <div className="content-btm">
            <Link
              to={`/car/${product._id}`}
              className="outlined-btn"
              style={{ width: "100%", textAlign: "center" }}
            >
              <div style={{ display: "table", margin: "auto" }}>
                View & Book &nbsp;&nbsp;
                <i className="ph-bold ph-paper-plane-right"></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
