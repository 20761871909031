import React from 'react';

const DashboardFooter = () => {
  return (
    <>
      <footer className="bg-white md:flex md:items-center md:justify-between shadow rounded-lg p-3 md:p-4 xl:p-6 my-6 mx-4">
        <p> Developed & maintained by <a href='www.napps.in' className="font-medium">napps</a> - &copy; Arjan motors, All rights reserved.</p>
      </footer>
    </>
  );
};

export default DashboardFooter;