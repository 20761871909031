import React, { useEffect, useState } from "react";
import { Link, useLoaderData, useLocation, useNavigation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";

import Slider from "react-slick";
import { restApiUri } from "../../constants";
import { FidgetSpinner } from "react-loader-spinner";
import LoadingSpinner from "../../dashboard/utilities/LoadingSpinner/LoadingSpinner";
import SoldSvg from "./images/sold.svg";
import { formatNumber } from "../../util/utils";

const SingleCar = (props) => {
  const product = useLoaderData();
  const { state } = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOfferOpen, setIsOfferOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [bestTime, setBestTime] = useState("");
  const [tradePrice, setTradePrice] = useState("");
  const [fullName, setFullName] = useState("");
  const [EnquiryNumber, setEnquiryNumber] = useState("");
  const [EnquiryEmail, setEnquiryEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [isLoading, setLoading] = useState(null);
  const [isRecentLoading, setRecentLoading] = useState(true);
  const [recentProducts, setRecentProducts] = useState([]);
  const [fullNameError, setFullNameError] = useState("");
  const [EnquiryEmailError, setEnquiryEmailError] = useState(""); 
  const [EnquiryNumberError, setEnquiryNumberError] = useState("");
  const [messageError, setMessageError] = useState("")

  const getRecentProducts = async () => {
    try {
      const res = await fetch(
        `${restApiUri}/recent-products?requestedNumber=1`
      );
      const data = await res.json();
      return data.products;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };
  

  const fetchData = async () => {
    setRecentLoading(true);
    const fetchedProducts = await getRecentProducts();
    setRecentProducts(fetchedProducts);
    setRecentLoading(false);
  };
  useEffect(() => {
    fetchData();
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 300,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    asNavFor: ".slider-for",
    dots: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openOfferModal = () => {
    setIsOfferOpen(true);
  };

  const closeOfferModal = () => {
    setIsOfferOpen(false);
  };

  const handleTestDrive = (e) => {
    const obj = {
      fullName: name,
      contactNumber: Number("91" + number),
      email: email,
      subject: `Test Drive on : ${product.carBrand} ${product.carModel} ${product.year}`,
      message: `\nScheduled Date & Time : ${bestTime} \nListing URL: ${
        window.location
      } \nBy : ${name} \nMobile: ${Number("91" + number)} \nEmail: ${email}`,
    };
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name.length === 0) {
      return toast.error("Name is Required.");
    }
    if (email.length === 0) {
      toast.error("Email is required");
      return false;
    } else if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (number.length === 0) {
      toast.error("Mobile Number is Required");
      return false;
    } else if (number.length < 10) {
      toast.error("Mobile Number should be at least 10 digits long");
      return false;
    }
    if (bestTime.length === 0) {
      return toast.error("Your Best Time is Required");
    }

    toast.success("Request of test drive is recieved");
    axios
      .post(`${restApiUri}/contact-us`, obj)
      .then((res) => {
        if (res.data.success === false) {
        } else if (res.data.success === true) {
          toast.success("Request of test drive is recieved");
          setFullName("");
          setEnquiryEmail("");
          setNumber("");
          setMessage("");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        toast.error("Something went wrong!");
        setLoading(false);
        // Handle generic error
      });
    closeModal();
  };

  const handleMakeOffer = (e) => {
    const obj = {
      fullName: name,
      contactNumber: Number("91" + number),
      email: email,
      subject: `Offer on : ${product.carBrand} ${product.carModel} ${product.year}`,
      message: `\nOfferPrice : ${tradePrice} \nListing URL: ${
        window.location
      } \nBy : ${name} \nMobile: ${Number("91" + number)} \nEmail: ${email}`,
    };
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name.length === 0) {
      return toast.error("Name is Required.");
    }
    if (email.length === 0) {
      toast.error("Email is required");
      return false;
    } else if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return false;
    }
    if (number.length === 0) {
      toast.error("Mobile Number is Required");
      return false;
    } else if (number.length < 10) {
      toast.error("Mobile Number should be at least 10 digits long");
      return false;
    }
    if (tradePrice.length === 0) {
      toast.error("Trade Price is Required");
      return false;
    } else if (tradePrice.length < 4) {
      toast.error("Trade Price should be at least 4 digits long");
      return false;
    }
    setLoading(true);
    axios
      .post(`${restApiUri}/contact-us`, obj)
      .then((res) => {
        if (res.data.success === false) {
        } else if (res.data.success === true) {
          toast.success("We've recieved your offer.");
          setFullName("");
          setEnquiryEmail("");
          setNumber("");
          setMessage("");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        toast.error("Something went wrong!");
        setLoading(false);
        // Handle generic error
      });
    closeOfferModal();
  };

  const handleEnquirySubmit = (e) => {
    const obj = {
      fullName: fullName,
      contactNumber: Number("91" + EnquiryNumber),
      email: EnquiryEmail,
      subject: `Inquiry : ${product.carBrand} ${product.carModel} ${product.year}`,
      message: `\nMobile : ${Number(
        "91" + EnquiryNumber
      )} \nName : ${fullName} \nMessage : ${message} `,
    };

    if (fullName.length === 0) {
      setFullNameError("Name is required.")
      return toast.error("Name is required.");
    }
    if (EnquiryEmail.length === 0) {
      setEnquiryEmailError("Email is required.")
      return toast.error("Email is required.");
    }
    if (EnquiryNumber.length === 0) {
      toast.error("Mobile Number is Required");
      return false;
    } else if (EnquiryNumber.length < 10) {
      toast.error("Mobile Number should be at least 10 digits long");
      return false;
    }
    if (message.length === 0) {
      setMessageError("Message is required.")
      return toast.error("Message is required.");
    }
    setLoading(true);

    axios
      .post(`${restApiUri}/contact-us`, obj)
      .then((res) => {
        if (res.data.success === false) {
        } else if (res.data.success === true) {
          toast.success(res.data.message);
          setFullName("");
          setEnquiryEmail("");
          setEnquiryNumber("");
          setMessage("");
          setFullNameError("");
          setEnquiryEmailError("");
          setEnquiryNumberError("");
          setMessageError("")
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        toast.error("Something went wrong!");
        setLoading(false);
        // Handle generic error
      });
  };
  console.log(fullNameError, "full name error");

  return (
    <div className="overflow-on-mobile">
      <div className="inner-page-banner">
        <div className="banner-wrapper">
          <div className="container" style={{ paddingBottom: "10px" }}>
            <ul id="breadcrumb-list" className="breadcrumb-list">
              <li className="breadcrumb-item">
                <Link to="/"> Home </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/cars">Inventory </Link>
              </li>
              {/* <li className="active">{product._id}</li> */}
            </ul>{" "}
            <div className="banner-main-content-wrap">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-content style-2">
                    <br />
                    <h1>{`${product.carBrand} ${product.carModel} ${product.year}, ${product?.exteriorColor}, ${product?.fuelType}`}</h1>
                    <div className="price-model-and-fav-area">
                      <div className="price-and-model">
                        <div className="price">
                          <h3 style={{ fontWeight: "bold" }}>
                            ₹ {formatNumber(product?.price)}
                            {/* <del>₹{product.originalPrice}</del> */}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="location-and-notification">
                      <ul>
                        <li>
                          <i className="bi bi-geo-alt" />
                          {product.location || "Delhi"}{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Banner section */}
      {/* Start Car-Details section */}
      <div
        className="car-details-area pt-0 mb-4 container"
        style={{ marginTop: "25px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-lg-8">
              <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-offset={0}
                className="scrollspy-example"
                tabIndex={0}
              >
                <div className="single-item   mb-10" id="car-img">
                  <div className="slider-wrapper">
                    <Slider
                      {...settingsMain}
                      asNavFor={nav2}
                      ref={(slider) => setSlider1(slider)}
                    >
                      {product?.images.map((slide, i) => (
                        <div className="slick-slide" key={"slide-sc-" + i}>
                          <img
                            className="slick-slide-image attachment-post-thumbnail size-post-thumbnail wp-post-image"
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              maxHeight: "400px",
                              height: "400px",
                              background: "black",
                              border: "1px solid silver",
                              borderRadius: "10px",
                            }}
                            src={slide}
                          />
                        </div>
                      ))}
                    </Slider>
                    <div
                      className="thumbnail-slider-wrap"
                      style={{ marginTop: "10px" }}
                    >
                      <Slider
                        {...settingsThumbs}
                        asNavFor={nav1}
                        ref={(slider) => setSlider2(slider)}
                      >
                        {product?.images.map((slide, i) => (
                          <div
                            className="slick-slide"
                            style={{ display: "flex" }}
                            key={"slide-sc-" + i}
                          >
                            <img
                              className="slick-slide-image"
                              style={{
                                border: "1px solid silver",
                                objectFit: "cover",
                                height: "100px",
                                width: "150px",
                                padding: "3px",
                                borderRadius: "5px",
                              }}
                              src={slide}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="single-item   mb-10">
                  <div className="car-info">
                    <div className="title mb-4">
                      <h5>Car Info</h5>
                    </div>
                    <ul className="car-info-ul">
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-gauge"
                        ></i>
                        <div className="content">
                          <h6>{product?.kmDriven} KMs</h6>
                          <span>Driven</span>
                        </div>
                      </li>
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-engine"
                        ></i>
                        <div className="content">
                          <h6>{product.engine} cc</h6>
                          <span>Engine</span>
                        </div>
                      </li>
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-gas-can"
                        ></i>
                        <div className="content">
                          <h6>{product?.fuelType}</h6>
                          <span>Fuel Type</span>
                        </div>
                      </li>
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-car-profile"
                        ></i>
                        <div className="content">
                          <h6>{product?.bodyType}</h6>
                          <span>Body Type</span>
                        </div>
                      </li>
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-gear-six"
                        ></i>
                        <div className="content">
                          <h6>{product?.transmission}</h6>
                          <span>Transmission</span>
                        </div>
                      </li>
                      <li>
                        <i
                          style={{ fontSize: "25px", color: "grey" }}
                          className="ph-bold ph-notepad"
                        ></i>
                        <div className="content">
                          <h6 style={{ textTransform: "capitalize" }}>
                            {product?.exteriorCondition}
                          </h6>
                          <span>Exterior Condition</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="single-item mb-10" id="kye-features">
                  <div className="kye-features">
                    <div className="title mb-4">
                      <h5>Key Features</h5>
                    </div>
                    <ul>
                      {product?.carFeatures.split(",").map((feature) => (
                        <li>
                          <i
                            style={{ fontSize: "20px", color: "grey" }}
                            className="ph-bold ph-shield-check"
                          ></i>
                          <p style={{ textTransform: "capitalize" }}>
                            {feature.trim()}{" "}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="single-item   mb-10" id="overview">
                  <div className="overview">
                    <div className="title mb-25">
                      <h5>Overviews</h5>
                    </div>
                    <div className="overview-content">
                      <ul>
                        <li>
                          <span>Make</span> {product.carBrand}
                        </li>
                        <li>
                          <span>Model</span> {product?.carModel}
                        </li>
                        <li>
                          <span>Year/Month</span> {product?.year}
                        </li>
                        <li>
                          <span>KMs Driven</span> {product?.kmDriven} KMs
                        </li>
                        <li>
                          <span>Fuel Type</span> {product?.fuelType}
                        </li>
                        <li>
                          <span>Seats</span> {product.seats}
                        </li>
                        <li>
                          <span>Engine</span> {product?.engine}
                        </li>
                        <li>
                          <span>Transmission</span> {product.transmission}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          <span>Exterior Color’s</span> {product?.exteriorColor}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          <span>Interior Color’s</span> {product?.interiorColor}
                        </li>
                        <li>
                          <span>Wheel’s</span> {product?.wheels}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          <span>Register State</span> {product?.registerState}
                        </li>
                        <li>
                          <span>Owner Serial</span> {product?.ownerSerial}
                        </li>
                        <li>
                          <span>Body Type</span> {product?.bodyType}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          <span>Exterior Condition</span>{" "}
                          {product?.exteriorCondition}
                        </li>
                        <li style={{ textTransform: "capitalize" }}>
                          <span>Interior Condition</span>{" "}
                          {product?.interiorCondition}
                        </li>
                        <li>
                          <span>Listed on</span> {product?.addDate}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {product?.description && (
                  <div className="single-item   mb-10" id="overview">
                    <div className="overview">
                      <div className="title mb-25">
                        <h5>Description</h5>
                      </div>
                      <div className="overview-content">
                        <p>{product.description}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="car-details-sidebar">
                {!product?.sellStatus ? (
                  <img
                    style={{
                      display: "table",
                      margin: "50px auto",
                      zoom: 0.7,
                      opacity: 0.8,
                    }}
                    src={SoldSvg}
                  />
                ) : (
                  <>
                    <div className="contact-info   mb-10">
                      <div className="single-contact" id="phoneNumber">
                        <a className="primary-btn7" href="tel:+917992990999">
                          <i className="fas fa-phone-alt" />
                          +917992990999
                        </a>
                      </div>
                      <br />
                      <div
                        className="single-contact"
                        id="emailAdress"
                        data-email="rajatsing@gmail.com"
                        onClick={openModal}
                      >
                        <a className="primary-btn7 book">
                          <i className="fas fa-envelope" />
                          Schedule Test Drive
                        </a>
                      </div>
                      <div
                        className="single-contact"
                        id="emailAdress"
                        data-email="rajatsingh@gmail.com"
                        onClick={openOfferModal}
                      >
                        <a className="primary-btn7 book">
                          <i className="fas fa-envelope" />
                          Make an offer
                        </a>
                      </div>

                      <a
                        href={`https://api.whatsapp.com/send?phone=917992990999&text=Hi! I am interested in this vehicle : ${window.location.href}`}
                        target="_blank"
                      >
                        <div className="single-contact" id="emailAdresss">
                          <a>
                            <i className="fab fa-whatsapp" />
                            Whatsapp
                          </a>
                        </div>
                      </a>
                    </div>

                    {/* Schedule test drive modal  */}

                    {isOpen && (
                      <div className="modal-single-car-overlay">
                        <div className="modal-single-car">
                          <div className="modal-header">
                            <h2>
                              <i
                                class="bi bi-calendar-date"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Schedule Test Drive
                            </h2>
                            <i
                              class="bi bi-x-circle"
                              style={{ fontSize: "25px", color: "#fff" }}
                              onClick={closeModal}
                            ></i>
                          </div>
                          <div
                            className="container"
                            style={{ padding: "10px 20px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Group className="mb-3 mt-3">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Phone Number</Form.Label>
                                  <div class="phone-input-container">
                                    <span>+91</span>
                                    <input
                                      type="number"
                                      value={number}
                                      placeholder="Enter your number"
                                      onChange={(e) => {
                                        if (e.target.value.length > 10) {
                                          return;
                                        }
                                        setNumber(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-md-6">
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Email address</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Best Time</Form.Label>
                                  <Form.Control
                                    type="datetime-local"
                                    placeholder="Enter your best time"
                                    onChange={(e) =>
                                      setBestTime(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                onClick={(e) => handleTestDrive(e)}
                                className="next-btn mb-3"
                              >
                                Request
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* make an offer modal  */}

                    {isOfferOpen && (
                      <div className="modal-single-car-overlay">
                        <div className="modal-single-car">
                          <div className="modal-header">
                            <h2>
                              <i
                                class="bi bi-calendar-date"
                                style={{ marginRight: "10px" }}
                              ></i>
                              Make an offer
                            </h2>
                            <i
                              class="bi bi-x-circle"
                              style={{ fontSize: "25px", color: "#fff" }}
                              onClick={closeOfferModal}
                            ></i>
                          </div>
                          <div
                            className="container"
                            style={{ padding: "10px 20px" }}
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <Form.Group className="mb-3 mt-3">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Phone Number</Form.Label>
                                  <div class="phone-input-container">
                                    <span>+91</span>
                                    <input
                                      type="number"
                                      value={number}
                                      placeholder="Enter your number"
                                      onChange={(e) => {
                                        if (e.target.value.length > 10) {
                                          return;
                                        }
                                        setNumber(e.target.value);
                                      }}
                                    />
                                  </div>
                                  {/* <Form.Control
                                    type="number"
                                    value={number}
                                    placeholder="Enter your number"
                                    onChange={(e) => {
                                      if (e.target.value.length > 10) {
                                        return;
                                      }
                                      setNumber(e.target.value);
                                    }}
                                  /> */}
                                </Form.Group>
                              </div>
                              <div className="col-md-6">
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Email address</Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Trade Price</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Your Trade Price"
                                    onChange={(e) =>
                                      setTradePrice(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="text-center">
                              <button
                                onClick={(e) => handleMakeOffer(e)}
                                className="next-btn mb-3"
                              >
                                Make an Offer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="inquiry-form   mb-10">
                  <div className="title">
                    <h4>To More inquiry </h4>
                    <p>Drop us an inquiry to know more on this listing.</p>
                  </div>
                  <div
                    className="wpcf7 no-js"
                    id="wpcf7-f239-o2"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                      <ul />
                    </div>

                    <div>
                      <Form.Group className="mb-3 mt-3">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control
                          type="text"
                          value={fullName}
                          placeholder="Ex-Rajat Singh"
                          onChange={(e) => setFullName(e.target.value)}
                        />
                        <small style={{color: "red", fontSize: "12px", fontWeight: "600"}}>{fullNameError}</small>
                      </Form.Group>
                      <Form.Group className="mb-3 mt-3">
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                          type="email"
                          value={EnquiryEmail}
                          placeholder="Ex- rajatsingh@gmail.com"
                          onChange={(e) => setEnquiryEmail(e.target.value)}
                        />
                         <small style={{color: "red", fontSize: "12px", fontWeight: "600"}}>{EnquiryEmailError}</small>
                      </Form.Group>
                      {/* <Form.Group className="mb-3 mt-3">
                        <Form.Label>Number (+91)*</Form.Label>
                        <Form.Control
                          type="number"
                          maxlength="10"
                          pattern="\d{10}"
                          value={EnquiryNumber}
                          placeholder="987654321"
                          onChange={(e) => {
                            if (e.target.value.length > 10) {
                              return;
                            }
                            setEnquiryNumber(e.target.value);
                          }}
                        />
                      </Form.Group> */}
                      <Form.Label>Number*</Form.Label>
                      <div class="phone-input-container">
                        <span>+91</span>
                        <input
                          type="number"
                          maxlength="10"
                          pattern="\d{10}"
                          value={EnquiryNumber}
                          placeholder="987654321"
                          onChange={(e) => {
                            if (e.target.value.length > 10) {
                              return;
                            }
                            setEnquiryNumber(e.target.value);
                          }}
                        />
                        
                      </div>
                      <small style={{color: "red", fontSize: "12px", fontWeight: "600"}}>{EnquiryNumberError}</small>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Message*</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={message}
                          placeholder="Write your message..."
                          rows={3}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                         <small style={{color: "red", fontSize: "12px", fontWeight: "600"}}>{messageError}</small>
                      </Form.Group>
                      <div className="form-inner">
                        <button
                          className="primary-btn3"
                          disabled={isLoading}
                          onClick={(e) => handleEnquirySubmit(e)}
                        >
                          {isLoading ? (
                            <Spinner animation="border" />
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={14}
                                height={14}
                                viewBox="0 0 14 14"
                              >
                                <path d="M13.8697 0.129409C13.9314 0.191213 13.9736 0.269783 13.991 0.355362C14.0085 0.44094 14.0004 0.529754 13.9678 0.610771L8.78063 13.5781C8.73492 13.6923 8.65859 13.7917 8.56003 13.8653C8.46148 13.9389 8.34453 13.9839 8.22206 13.9954C8.09958 14.0068 7.97633 13.9842 7.86586 13.9301C7.75539 13.876 7.66199 13.7924 7.59594 13.6887L4.76304 9.23607L0.310438 6.40316C0.206426 6.33718 0.122663 6.24375 0.0683925 6.13318C0.0141218 6.02261 -0.00854707 5.89919 0.00288719 5.77655C0.0143215 5.65391 0.0594144 5.53681 0.13319 5.43817C0.206966 5.33954 0.306557 5.2632 0.420973 5.21759L13.3883 0.0322452C13.4694 -0.000369522 13.5582 -0.00846329 13.6437 0.00896931C13.7293 0.0264019 13.8079 0.0685926 13.8697 0.1303V0.129409ZM5.65267 8.97578L8.11385 12.8427L12.3329 2.29554L5.65267 8.97578ZM11.7027 1.66531L1.1555 5.88436L5.02333 8.34466L11.7027 1.66531Z"></path>
                              </svg>{" "}
                              Send Enquiry
                            </>
                          )}
                        </button>
                      </div>
                      {/* <div className='text-center'>
                                            <button onClick={(e) => handleEnquirySubmit(e)} className='next-btn mb-3'>Make an Offer</button>
                                        </div> */}
                    </div>
                  </div>
                </div>
                <div className="recent-car-area">
                  <div className="title mb-30">
                    <h5>Just Posted</h5>
                  </div>
                  {isRecentLoading ? (
                    <LoadingSpinner />
                  ) : (
                    recentProducts.length > 0 && (
                      <div className="tab-content" id="myTab6Content">
                        <Link to={`/car/${recentProducts[0]._id}`}>
                          <div
                            className="tab-pane fade show active"
                            id="new-car0"
                            role="tabpanel"
                            aria-labelledby="new-car-tab"
                          >
                            <div className="product-st-card1 two">
                              <div className="product-img">
                                <div className="product-price">
                                  <span>₹ {recentProducts[0]?.price}</span>
                                </div>
                                <div className="car-img">
                                  <img
                                    width={900}
                                    height={485}
                                    src={recentProducts[0]?.images[0]}
                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                    alt=""
                                    decoding="async"
                                    srcSet={recentProducts[0]?.images[0]}
                                    sizes="(max-width: 900px) 100vw, 900px"
                                  />{" "}
                                </div>
                              </div>
                              <div className="product-content">
                                <h6>
                                  <Link to={`/car/${recentProducts[0]._id}`}>
                                    {recentProducts[0]?.carBrand}{" "}
                                    {recentProducts[0]?.carModel}
                                  </Link>
                                </h6>
                                <ul className="features">
                                  <li>
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        color: "silver",
                                      }}
                                      className="ph-bold ph-gauge"
                                    ></i>
                                    {recentProducts[0]?.kmDriven} Kms{" "}
                                  </li>
                                  <li>
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        color: "silver",
                                      }}
                                      className="ph-bold ph-gas-can"
                                    ></i>
                                    {recentProducts[0]?.fuelType}{" "}
                                  </li>
                                  <li>
                                    <i
                                      style={{
                                        fontSize: "25px",
                                        color: "silver",
                                      }}
                                      className="ph-bold ph-engine"
                                    ></i>
                                    {recentProducts[0]?.engine} cc{" "}
                                  </li>
                                  <li>
                                    <i
                                      style={{
                                        fontSize: "20px",
                                        color: "silver",
                                      }}
                                      className="ph-bold ph-calendar-blank"
                                    ></i>
                                    {recentProducts[0]?.year}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  )}
                </div>
                {/* <div className="side-banner mt-50">
                                <a href="../index.html">
                                    <img
                                        src="../../content/uploads/2023/10/blog-bottom-banner.jpg"
                                        alt=""
                                    />
                                </a>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCar;
