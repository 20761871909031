
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { restApiUri } from '../../constants';
import toast from 'react-hot-toast';

const FeedbackForm = ({ onSubmit }) => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [rating, setRating] = useState(5);
    const [feedback, setFeedback] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const newFeedback = {
            name,
            company,
            rating: Number(rating),
            feedback,
            contactNumber: number,
            email
        };

        // Send POST request to save feedback
        axios.post(`${restApiUri}/feedback`, newFeedback).then(() => {
            toast.success('Thanks, We have received your feedback!')
            setName('');
            setCompany('');
            setRating(5);
            setFeedback('');
            setEmail('')
            setNumber('')
        });
    };

    return (
        <div className="feedback-form container mt-5 mb-5">
            <h2>Leave Feedback</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name *</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="company">Company/Occupation/City</label>
                    <input
                        type="text"
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="company">Contact Number *</label>
                    <div class="phone-input-container">
                        <span>+91</span>
                        <input
                            type="number"
                            maxLength="10"
                            id="number"
                            pattern="\d{10}"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="company">Email </label>
                    <input
                        type="email"
                        required
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="rating">Rating *</label>
                    <select
                        id="rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                        required
                        className="form-select"
                    >
                        <option value="5">5 Stars</option>
                        <option value="4">4 Stars</option>
                        <option value="3">3 Stars</option>
                        <option value="2">2 Stars</option>
                        <option value="1">1 Star</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="feedback">Message *</label>
                    <textarea
                        id="feedback"
                        value={feedback}
                        rows={4}
                        onChange={(e) => setFeedback(e.target.value)}
                        required
                        className="form-control"
                        maxLength="200"
                        aria-describedby="feedback-helper"
                    ></textarea>
                    <p id="feedback-helper" class="text-muted">
                        Share your experience in 200 words or less.
                    </p>
                </div>
                <br />
                <button type="submit" className="btn btn-primary" style={{ background: '#D7B65D' }}>
                    Submit Feedback
                </button>
            </form>
        </div>
    );
};

export default FeedbackForm;