import React from 'react';
import AdvetiseProducts from './AdvertiseProducts/AdvetiseProducts';
import Banner from './Banner/Banner';
import ProductsBrandHome from './ProductsBrandHome/ProductsBrandHome';
import ProductsCategoryHome from './ProductsCategoryHome/ProductsCategoryHome';
import ProductsHome from './ProductsHome/ProductsHome';
import SellYourCar from './SellYourCar/SellCarGrid';
import RecentLaunch from './RecentLaunch/RecentLaunch';
import HowItsWork from './HowItsWork/HowItsWork';
import CustomerReviews from './CustomerReviews/CustomerReviews';
import WhyChooseUs from './WhyChooseUs/WhyChooseUs';
import HappyCustomer from './CustomerReviews/HappyCustomer';

const HomePage = () => {
  return (
    <>
      <div className="overflow-on-mobile">
        <Banner />
        <RecentLaunch/>
        <ProductsBrandHome />
    
        {/* <ProductsCategoryHome></ProductsCategoryHome> */}

        <SellYourCar/>
        <HowItsWork/>
        
        <WhyChooseUs></WhyChooseUs>
        <HappyCustomer></HappyCustomer>
        <CustomerReviews/>
      </div>
    </>
  );
};

export default HomePage;