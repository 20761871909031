import { Link } from "react-router-dom";

 const InnerBanner = () => {

    return (
        <div className="inner-page-banner">
            <div className="banner-wrapper">
                <div className="container">
                    <ul id="breadcrumb-list" className="breadcrumb-list">
                        <li className="breadcrumb-item">
                            <Link to="/"> Home </Link>
                        </li>
                        <li className="active">Inventory</li>
                    </ul>
                    <div className="banner-main-content-wrap">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 d-flex align-items-center inner-banner-heading">
                                <div className="banner-content">
                                    <h1 >Your dream car awaits in our collection—find it today!</h1>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 d-lg-flex d-none align-items-center justify-content-end">
                                <div className="banner-img">
                                    <img
                                        src="../../content/plugins/drivco-core/inc/theme-options/images/breadcrumb/breadcrumb-right-img.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InnerBanner;