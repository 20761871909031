
// npm install react-filepond filepond --save
import React, { useState, useEffect } from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import { getBlob, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import { Button } from "@chakra-ui/react";
import { storage } from "../../firebase/firebase.config";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform
);

// Our app
export function FilePondComponent(props) {
  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  let pond = null;

  useEffect(()=>{console.log('did mount')},[])

  useEffect(() => {
    props.setFiles(files)
  }, [files])

  useEffect(() => {
    props.setPreviews(props.previews)
  }, [props.previews])

  useEffect(()=>{
    console.log(props?.editImagesData)
    if(props?.editImagesData){
      fetchBlobsFromFirebaseUrls(props?.editImagesData)
      .then(blobs => {
        console.log('Blobs:', blobs);
        // Use the blobs as needed
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error
      });
    }

  },[])

  console.log(props, "uploaded Images");

  return (
    <div className="App" style={{ border: '1px solid black', borderRadius: '5px', background: 'whitesmoke' }}>
      <FilePond
        // files={previews}
        // ref={(ref) => {
        //   pond = ref;
        // }}
        required
        acceptedFileTypes={["application/pdf", "image/*"]}
        fileValidateTypeDetectType={(source, type) =>
          // Note: we need this here to activate the file type validations and filtering
          new Promise((resolve, reject) => {
            // Do custom type detection here and return with promise
            resolve(type);
          })
        }
        allowFileEncode
        allowImageTransform
        imagePreviewHeight={400}
        imageCropAspectRatio={"1:1"}
        imageResizeTargetWidth={100}
        imageResizeTargetHeight={100}
        imageResizeMode={"cover"}
        imageTransformOutputQuality={50}
        imageTransformOutputQualityMode="optional"
        imageTransformBeforeCreateBlob={(canvas) =>
          new Promise((resolve) => {
            // Do something with the canvas, like drawing some text on it
            const ctx = canvas.getContext("2d");
            ctx.font = "48px serif";
            ctx.fillText("Arjan Motors", 10, 50);
            // return canvas to the plugin for further processing
            resolve(canvas);
          })
        }
        imageTransformAfterCreateBlob={(blob) =>
          new Promise((resolve) => {
            // do something with the blob, for instance send it to a custom compression alogrithm
            // return the blob to the plugin for further processing
            resolve(blob);
          })
        }
        onupdatefiles={(fileItems) => {
          const files = fileItems.map((fileItem) => fileItem.file)
          setFiles(files)
          setPreviews(fileItems)
        }}
        instantUpload={false}
        allowMultiple={true}
        maxFiles={200}
        // server="https://httpbin.org/post"
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />

      {/* <Button onClick={onSubmit}>Submit</Button> */}
    </div>
  );
}

async function extractPathAndFetchBlobFromFirebaseUrl(url) {
  try {
    // Extract path from the Firebase Storage URL
    const path = extractPathFromFirebaseUrl(url);
    console.log(path,'path=========>')

    // Create reference to the file using the extracted path
    const storageRef = ref(storage, path);
    console.log(storageRef,'storageRef===>')
    // Get the blob of the image
    const blob = await getBlob(storageRef);
    console.log(blob,'blob===>')
    return blob;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchBlobsFromFirebaseUrls(urls) {
  try {
    const blobs = [];
    for (const url of urls) {
      const blob = await extractPathAndFetchBlobFromFirebaseUrl(url);
      blobs.push(blob);
    }
    return blobs;
  } catch (error) {
    console.error('Error fetching blobs:', error);
    throw error;
  }
}


function extractPathFromFirebaseUrl(url) {
  const regex = /\/o\/(.*?)\?/;
  const match = regex.exec(url);
  if (match) {
    const decodedPath = decodeURIComponent(match[1]);
    return decodedPath;
  } else {
    throw new Error('Failed to extract path from URL');
  }
}