import React from 'react';
import './bootstrap.min.css'
import './index.css';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes/Routes';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function App() {
  return (
    <>
      <RouterProvider router={router}>
        
      </RouterProvider>
    </>
  );
}

export default App;
