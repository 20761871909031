import React, { useEffect } from 'react';
import AdvetiseProducts from './AdvertiseProducts/AdvetiseProducts';
import Banner from './Banner/Banner';
import ProductsBrandHome from './ProductsBrandHome/ProductsBrandHome';
import ProductsCategoryHome from './ProductsCategoryHome/ProductsCategoryHome';
import ProductsHome from './ProductsHome/ProductsHome';
import Filters from '../Products/Filters';
import { useLocation } from 'react-router-dom';

const Home = () => {
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <Banner></Banner>
        <AdvetiseProducts></AdvetiseProducts>
        <ProductsCategoryHome></ProductsCategoryHome>
        <ProductsBrandHome></ProductsBrandHome>
        <ProductsHome></ProductsHome>
      </div>
    </>
  );
};

export default Home;