import React from 'react';

const SellWithUs = () => {
  return (
    <div className='container'>
      <h2 className='text-3xl font-bold'>Sell With US</h2>
    </div>
  );
};

export default SellWithUs;