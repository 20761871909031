import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll, getMetadata, updateMetadata } from 'firebase/storage';
import { Button, Col, Row, Form, Spinner } from 'react-bootstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'react-bootstrap';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';

const UploadBanners = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const storage = getStorage(); // Get Firebase Storage reference
    const [metaOfImages, setMetaOfImages] = useState({})
    const [isFetching, setFetching] = useState(false)
    const fetchUploadedImages = async () => {
        setFetching(true)
        try {
            const storage = getStorage();
            const bannerRef = ref(storage, 'banners');
            const images = await listAll(bannerRef);

            // Now 'images' contains all items (files and subdirectories)
            // inside the 'banner' directory

            const uploadedImagesData = [];

            // Fetch metadata for each image
            const metadataPromises = images.items.map(async (itemRef) => {
                const url = await getDownloadURL(ref(itemRef));
                const metadata = await getMetadata(ref(itemRef));
                console.log(metadata)
                const imageData = {
                    url: url,
                    metadata: metadata,
                };
                uploadedImagesData.push(imageData);
            });

            // Wait for all metadata to be fetched
            await Promise.all(metadataPromises);
            console.log(uploadedImagesData)
            // Set state with the uploaded images data
            setUploadedImages(uploadedImagesData);
            setFetching(false)
        } catch (error) {
            setFetching(false)
            console.error('Error fetching uploaded images:', error);
        }
    };
    useEffect(() => {
        // Optional: Fetch previously uploaded images on component mount
        // Fetch previously uploaded images on component mount
  
        fetchUploadedImages();
    }, []); // Empty dependency array to run only once on mount

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            setSelectedFiles(Array.from(files)); // Convert FileList to array
        } else {
            setSelectedFiles([]);
        }
    };

    const handleDeleteImage = async (imagePath) => {
        console.log(imagePath)
        const storageRef = ref(storage, imagePath);
        try {
            await deleteObject(storageRef); // Delete the image from Firebase Storage
            setUploadedImages(uploadedImages.filter((image) => image.metadata.fullPath !== imagePath)); // Remove deleted image from state
        } catch (error) {
            console.error('Error deleting image:', error);
            setUploadMessage('Failed to delete image. Please try again.');
        }
    };

    const handleImageSubmit = async (event, imageUrl) => {
        event.preventDefault();
        // Add your logic to post data to API with image URL (imageUrl), caption, and action URL
        console.log('Submit image data:', imageUrl);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (selectedFiles.length === 0) {
            setUploadMessage('Please select images to upload.');
            return;
        }

        setIsUploading(true);
        setUploadMessage('Uploading images...');
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          }
        const uploadTasks = [];
        for (const file of selectedFiles) {
            const compressedFile = await imageCompression(file, options);
            let fileNameToAdd = file.name
            if (metaOfImages[file.name]) {
                fileNameToAdd = fileNameToAdd + '#napps#' + JSON.stringify(metaOfImages[file.name])
            }
            const storageRef = ref(storage, `banners/${fileNameToAdd}`); // Upload to 'banners' directory
            console.log(file)
            const uploadTask = await uploadBytes(storageRef, compressedFile);
            // const newMetadata = {...};
            await updateMetadata(storageRef, metaOfImages[file.name] || {});
            uploadTasks.push(uploadTask);
        }

        try {
            const uploadResults = await Promise.all(uploadTasks);
            const newUploadedImages = uploadResults.map(async (result) => ({
                path: result.metadata.name, // Use metadata.name for path extraction
                url: await getDownloadURL(result.ref),
                // caption: metaOfImages[file.name]?.caption || '',
                // actionUrl: metaOfImages[file.name]?.actionUrl || ''
            }));
            // setUploadedImages([...uploadedImages, ...newUploadedImages]);
            fetchUploadedImages();
            setSelectedFiles([]);
            setUploadMessage('Images uploaded successfully!');
            toast.success('Images uploaded successfully!')
        } catch (error) {
            console.error('Upload error:', error);
            setUploadMessage('Upload failed. Please try again.');
        } finally {
            setIsUploading(false); // Ensure button gets re-enabled even on error
        }
    };

    return (
        <div>
            <h5>Home Banners</h5>
            <form onSubmit={handleSubmit}>
                <input type="file"  accept="image/*" className='form-control' value="" multiple onChange={handleFileChange} />
                <br />
                <div className="image-previews">
                    <Row>
                        {selectedFiles.map((file, index) => (
                            <Col key={index} sm={4}>
                                <div key={index} className="image-preview">
                                    <img style={{ maxHeight: '300px', maxWidth: '350px' }} src={URL.createObjectURL(file)} alt={`Preview of ${file.name}`} />
                                    <button
                                        style={{
                                            border: '1px solid black',
                                            borderRadius: '50%',
                                            padding: '0.2rem 0.7rem',
                                            background: 'white',
                                            position: 'absolute',
                                            margin: '-45px 0px 0px 10px'
                                        }}
                                        onClick={() => setSelectedFiles(selectedFiles.filter((f) => f !== file))}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                    <Form onSubmit={(e) => handleImageSubmit(e, URL.createObjectURL(file))}>
                                        <Form.Group controlId={`caption-${index}`}>
                                            <Form.Label>Caption</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="eg: Drop Us a Message in Our Inbox!"
                                                value={metaOfImages[file.name]?.caption || ''}
                                                onChange={(e) => {
                                                    const newMetaOfImages = { ...metaOfImages };
                                                    if (!newMetaOfImages[file.name]) {
                                                        newMetaOfImages[file.name] = {}
                                                    }
                                                    newMetaOfImages[file.name].caption = e.target.value;
                                                    setMetaOfImages(newMetaOfImages);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`actionUrl-${index}`}>
                                            <Form.Label>Action URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="eg: contact-us"
                                                value={metaOfImages[file.name]?.actionUrl || ''}
                                                onChange={(e) => {
                                                    const newMetaOfImages = { ...metaOfImages };
                                                    if (!newMetaOfImages[file.name]) {
                                                        newMetaOfImages[file.name] = {}
                                                    }
                                                    newMetaOfImages[file.name].actionUrl = e.target.value.replace('/', '');
                                                    setMetaOfImages(newMetaOfImages);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`actionUrl-${index}`}>
                                            <Form.Label>Button Label</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="eg : Call Us Now"
                                                value={metaOfImages[file.name]?.buttonLabel || ''}
                                                onChange={(e) => {
                                                    const newMetaOfImages = { ...metaOfImages };
                                                    if (!newMetaOfImages[file.name]) {
                                                        newMetaOfImages[file.name] = {}
                                                    }
                                                    newMetaOfImages[file.name].buttonLabel = e.target.value;
                                                    setMetaOfImages(newMetaOfImages);
                                                }}
                                            />
                                        </Form.Group>
                                    </Form>

                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                <br />
                <Button className='btn btn-primary' type="submit" disabled={isUploading}>
                    {isUploading ? (
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    ) : (
                        'Upload Images'
                    )}
                </Button>
                <br />
                <br />
                <p>Image resolution must be of 1920x680</p>
            </form>
            {uploadMessage && <p style={{ fontWeight: 'bold' }}>{uploadMessage}</p>}
            {isFetching && <><p>Fetching Images...</p><br /><Spinner /></>}
            <div className="image-grid">
                <Row>
                    {uploadedImages.map((image, index) => {
                        let savedDetails = image.metadata.name.split('#napps#')
                        if (savedDetails.length > 1) {
                            savedDetails = savedDetails[1]
                            savedDetails = JSON.parse(savedDetails)
                        }
                        console.log(savedDetails)
                        return (
                            <Col key={index} sm={3}>
                                <div key={index} className="image-container" >
                                    {console.log(image)}
                                    <img src={image.url} alt="Uploaded Image" />
                                    {!Array.isArray(savedDetails) && <>
                                        <p style={{ marginTop: '10px' }}>Caption: <strong>{savedDetails.caption}</strong></p>
                                        <p>Action URL: <strong>{window.location.origin + '/' + savedDetails.actionUrl}</strong></p>
                                        <p>Button Label: <strong>{savedDetails.buttonLabel}</strong></p>
                                    </>}
                                    <button style={{
                                        border: '1px solid black',
                                        borderRadius: '50%',
                                        padding: '0.2rem 0.7rem',
                                        background: 'white',
                                        margin: '5px auto'
                                    }} onClick={() => {
                                        const isConfirm = window.confirm('You are deleting this image!')
                                        if (isConfirm) {
                                            handleDeleteImage(image.metadata.fullPath)
                                        }
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </div>
                            </Col>)
                    })}
                </Row>
            </div>
        </div>
    );
};




const UploadGallery = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const storage = getStorage(); // Get Firebase Storage reference
    const [metaOfImages, setMetaOfImages] = useState({})
    const [isFetching, setFetching] = useState(false)
    const fetchUploadedImages = async () => {
        setFetching(true)
        try {
            const storage = getStorage();
            const bannerRef = ref(storage, 'gallery');
            const images = await listAll(bannerRef);

            // Now 'images' contains all items (files and subdirectories)
            // inside the 'banner' directory

            const uploadedImagesData = [];

            // Fetch metadata for each image
            const metadataPromises = images.items.map(async (itemRef) => {
                const url = await getDownloadURL(ref(itemRef));
                const metadata = await getMetadata(ref(itemRef));
                console.log(metadata)
                const imageData = {
                    url: url,
                    metadata: metadata,
                };
                uploadedImagesData.push(imageData);
            });

            // Wait for all metadata to be fetched
            await Promise.all(metadataPromises);
            // Set state with the uploaded images data
            setUploadedImages(uploadedImagesData);
            setFetching(false)
        } catch (error) {
            console.error('Error fetching uploaded images:', error);
            setFetching(false)
        }
    };
    useEffect(() => {
        // Optional: Fetch previously uploaded images on component mount
        // Fetch previously uploaded images on component mount
  
        fetchUploadedImages();
    }, []); // Empty dependency array to run only once on mount

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            setSelectedFiles(Array.from(files)); // Convert FileList to array
        } else {
            setSelectedFiles([]);
        }
    };

    const handleDeleteImage = async (imagePath) => {
        console.log(imagePath)
        const storageRef = ref(storage, imagePath);
        try {
            await deleteObject(storageRef); // Delete the image from Firebase Storage
            setUploadedImages(uploadedImages.filter((image) => image.metadata.fullPath !== imagePath)); // Remove deleted image from state
        } catch (error) {
            console.error('Error deleting image:', error);
            setUploadMessage('Failed to delete image. Please try again.');
        }
    };

    const handleImageSubmit = async (event, imageUrl) => {
        event.preventDefault();
        // Add your logic to post data to API with image URL (imageUrl), caption, and action URL
        console.log('Submit image data:', imageUrl);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (selectedFiles.length === 0) {
            setUploadMessage('Please select images to upload.');
            return;
        }

        setIsUploading(true);
        setUploadMessage('Uploading images...');
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
          }
        const uploadTasks = [];
        for (const file of selectedFiles) {
            const compressedFile = await imageCompression(file, options);
            const storageRef = ref(storage, `gallery/${file.name}`); // Upload to 'banners' directory
            console.log(file)
            const uploadTask = await uploadBytes(storageRef, compressedFile);
            // const newMetadata = {...};
            await updateMetadata(storageRef, metaOfImages[file.name] || {});
            uploadTasks.push(uploadTask);
        }
        try {
            const uploadResults = await Promise.all(uploadTasks);
            const newUploadedImages = uploadResults.map(async (result) => ({
                path: result.metadata.name, // Use metadata.name for path extraction
                url: await getDownloadURL(result.ref),
                // caption: metaOfImages[file.name]?.caption || '',
                // actionUrl: metaOfImages[file.name]?.actionUrl || ''
            }));
            // setUploadedImages([...uploadedImages, ...newUploadedImages]);
            fetchUploadedImages();
            setSelectedFiles([]);
            setUploadMessage('Images uploaded successfully!');
            toast.success('Images uploaded successfully!')
        } catch (error) {
            console.error('Upload error:', error);
            setUploadMessage('Upload failed. Please try again.');
        } finally {
            setIsUploading(false); // Ensure button gets re-enabled even on error
        }
    };

    return (
        <div>
            <h5>Gallery</h5>
            <form onSubmit={handleSubmit}>
                <input type="file"  accept="image/*" className='form-control' value="" multiple onChange={handleFileChange} />
                <br />
                <div className="image-previews">
                    <Row>
                        {selectedFiles.map((file, index) => (
                            <Col key={index} sm={4} style={{ width: 'max-content', marginBottom: '10px' }}>
                                <div key={index} className="image-preview">
                                    <img style={{ height: '300px', width: '350px', background: 'black', objectFit: 'contain' }} src={URL.createObjectURL(file)} alt={`Preview of ${file.name}`} />
                                    <button
                                        style={{
                                            border: '1px solid black',
                                            borderRadius: '50%',
                                            padding: '0.2rem 0.7rem',
                                            background: 'white',
                                            position: 'absolute',
                                            margin: '-45px 0px 0px 10px'
                                        }}
                                        onClick={() => setSelectedFiles(selectedFiles.filter((f) => f !== file))}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                <br />
                <Button className='btn btn-primary' type="submit" disabled={isUploading}>
                    {isUploading ? (
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    ) : (
                        'Upload Images'
                    )}
                </Button>
                <br />
                <br />
                <p>Image resolution must be of 350x300</p>
            </form>
            {uploadMessage && <p>{uploadMessage}</p>}
            {isFetching && <><p>Fetching Images...</p><br /><Spinner /></>}
            <div className="image-grid">
                <Row>
                    {uploadedImages.map((image, index) => {
                        return (
                            <Col key={index} sm={4} style={{ width: 'max-content', marginBottom: '10px' }}>
                                <div key={index} className="image-preview" >
                                    <img style={{ height: '300px', width: '350px', background: 'black', objectFit: 'contain' }} src={image.url} alt="Uploaded Image" />
                                    <button style={{
                                        border: '1px solid black',
                                        borderRadius: '50%',
                                        padding: '0.2rem 0.7rem',
                                        background: 'white',
                                        margin: '5px auto'
                                    }} onClick={() => {
                                        const isConfirm = window.confirm('You are deleting this image!')
                                        if (isConfirm) {
                                            handleDeleteImage(image.metadata.fullPath)
                                        }
                                    }}>
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </div>
                            </Col>)
                    })}
                </Row>
            </div>
        </div>
    );
};

const BannerGallery = () => {
    const [activeKey, setActiveKey] = useState('banners'); // Initial active tab

    const handleSelect = (key) => { setActiveKey(key) }
    return (
        <div>
            <Nav variant="tabs">
                <NavItem>
                    <NavLink eventKey="banners" onClick={() => handleSelect('banners')}>
                        Upload Banners
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink eventKey="gallery" onClick={() => handleSelect('gallery')}>
                        Upload Gallery
                    </NavLink>
                </NavItem>
            </Nav>
            <br />
            <div >
                {activeKey === 'banners' && <UploadBanners />}
                {activeKey === 'gallery' && <UploadGallery />}
            </div>
        </div>
    )
}

export default BannerGallery;